import { REPORT } from "../constants";

const initialState = {
  totalReportStats: {
    denchu: "-",
    hh: "-",
    mh: "-",
  },
  reportData: [],
  reportTagFilterData: [],
  totalReportCount: "--",
  currentPageNumber: 1,
  filteredTotal: "--",
  filter: {
    type: "occurred_datetime",
    order: "desc",
    tag: [],
    filterType: "report",
    assetId: null,
    signNumber: null,
    assetType: "all",
    manageGroup: {
      key: "all",
      value: [],
    }
  },
  downloadData: [],
  reportImageData: [],
  reportImageDataByOthers: [],
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case REPORT.SET_TOTAL_STATS_DATA:
      return {
        ...state,
        totalReportStats: action.payload,
      };
    case REPORT.SET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
      };
    case REPORT.SET_REPORT_TAG_FILTER_DATA:
      return {
        ...state,
        reportTagFilterData: action.payload,
      };
    case REPORT.SET_REPORT_TOTAL_IMAGE_COUNT:
      return {
        ...state,
        totalReportCount: action.payload,
      };
    case REPORT.SET_REPORT_CURRENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case REPORT.SET_REPORT_FILTERED_TOTAL:
      return {
        ...state,
        filteredTotal: action.payload,
      };
    case REPORT.SET_REPORT_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case REPORT.SET_DOWNLOAD_REPORT_DATA:
      return {
        ...state,
        downloadData: action.payload,
      };
    case REPORT.SET_IMAGE_DATA_BY_REPORT_ID:
      return {
        ...state,
        reportImageData: action.payload,
      };
    case REPORT.SET_IMAGE_DATA_BY_OTHER_USERS:
      return {
        ...state,
        reportImageDataByOthers: action.payload,
      };
    default:
      return state;
  }
}
