import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import ReactImageMagnify from 'react-image-magnify';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from 'lodash';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImageCard from './ImageCard';
const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '20px',
      justifyContent: "center",
    },
    thumbnailSliderWrapper: {
      position: 'relative',
      width: 100,
      marginRight: 16,
    },
    thumbnailSlider: {
      width: 100,
      marginRight: 16,
      '.slick-list': {
        height: "440px",
        overflow: 'hidden',
      },
    },
    thumbnail: {
      width: '100%',
      cursor: 'pointer',
      border: '2px solid transparent',
      marginBottom: 8,
      '&:hover': {
        border: '2px solid #ccc',
      },
    },
    imageMagnify: {
      flex: 1,
      maxWidth: 1200,
      position: 'relative',
    },
    magnifyWrapper: {
      position: 'relative',
      display: 'flex',
      width: '1200px',
    },
    magnifyPlaceholder: {
      position: 'relative',
      top: 0,
      left: 10,
      width: '795px',
      minHeight: '45px',
      maxWidth: 1200,
      border: '2px dashed #ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#333',
      fontSize: '14px',
      zIndex: 1,
      pointerEvents: 'none',
    },
    hidden: {
      display: 'none',
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    arrow: {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '50%',
      padding: '5px',
      cursor: 'pointer',
      left: '33%',
    },
    arrowUp: {
      top: '-55px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    arrowDown: {
      bottom: '-40px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    noImageContainer: {
      width: '795px',
      minHeight: '20px',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      marginBottom: theme.spacing(8),
    },
    noImagePlaceholder: {
      padding: theme.spacing(2),
      border: "1px dashed #ccc",
      borderRadius: "8px",
      color: "#666",
      fontSize: "16px",
      fontWeight: "bold",
    },
  })
);

const CustomArrowUp = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <ExpandLessIcon className={`${classes.arrow} ${className}`} onClick={onClick} style={{marginTop: "-30px"}} />
  );
};

const CustomArrowDown = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <ExpandMoreIcon className={`${classes.arrow} ${className}`} onClick={onClick} />
  );
};

const ReportMagnify = ({ viewer, reportImageDataByOthers }) => {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const containerRef = useRef(null); // コンテナの参照を取得
  const [selectedImage, setSelectedImage] = useState([]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [images, setImages] = useState([]);
  const [isImageExist, setImageExist] = useState(false);

  useEffect(() => {
    if (viewer.length > 0) {
      let imageUrls = {}, imageUrlsArray = [];
      if (!viewer[0]["fileName"]) {
        return
      }
      const uniquePhotoData = _.uniqWith(viewer[0].allPhotoUrl, _.isEqual);
      const imageUrlArray = uniquePhotoData;
      _.each(imageUrlArray, function(photoData) {
        imageUrls.small = `${S3_BUCKET_BASE_URL}/${photoData}`;
        imageUrls.large = `${S3_BUCKET_BASE_URL}/${photoData}`;
        imageUrls.fileName = photoData.split("Report/")[1];
        imageUrlsArray.push(imageUrls);
        imageUrls = {};
      });
      setImages(imageUrlsArray);
      setImageExist(true);
    }
  }, [viewer]);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);

      const handleWheel = (e) => {
        e.preventDefault();
        if (e.deltaY < 0) {
          sliderRef.current.slickPrev();
        } else {
          sliderRef.current.slickNext();
        }
      };

      const containerElement = containerRef.current; // コンテナ要素を取得

      if (containerElement) {
        containerElement.addEventListener('wheel', handleWheel);
      }

      return () => {
        if (containerElement) {
          containerElement.removeEventListener('wheel', handleWheel);
        }
      };
    }
  }, [images]);

  const settings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    focusOnSelect: true,
    prevArrow: <CustomArrowUp />,
    nextArrow: <CustomArrowDown />,
  };

  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const openImageInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = selectedImage.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object after download
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  return (
    <div ref={containerRef} className={classes.container}>
      {isImageExist &&
      <>
      <div className={classes.thumbnailSliderWrapper}>
        <CustomArrowUp onClick={() => sliderRef.current.slickPrev()} />
        <div className={classes.thumbnailSlider} style={{ height: '450px', overflow: 'hidden' }}>
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
              <div key={index} onClick={() => handleThumbnailClick(index)}>
                <img
                  src={image.small}
                  alt={`Thumbnail ${index}`}
                  className={classes.thumbnail}
                />
              </div>
            ))}
          </Slider>
        </div>
        <CustomArrowDown onClick={() => sliderRef.current.slickNext()} />
      </div>
      <div className={classes.imageMagnify}>
        <div className={classes.magnifyWrapper}>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Selected Image',
                  isFluidWidth: false,
                  width: 300,
                  height: 450,
                  src: selectedImage.small,
                },
                largeImage: {
                  src: selectedImage.large,
                  width: 3000,
                  height: 4200,
                },
                enlargedImageContainerDimensions: {
                  width: "265%",
                  height: "100%",
                },
              }}
            />
          </div>
          {!isZoomed && (
            <div className={classes.magnifyPlaceholder}>
              画像にマウスを合わせると拡大が表示されます
            </div>
          )}
        </div>
        <Button
          className={classes.dataBtn}
          variant="contained"
          onClick={() => openImageInNewTab(selectedImage.large)}
        >
          元画像を開く
        </Button>
      </div>
      </>
      }
      {!isImageExist && (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item xs={12} className={classes.noImageContainer}>
            <div className={classes.noImagePlaceholder}>報告画像はありません</div>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Typography variant="body3" component="p">
              同一アセットが撮られた他の参考写真
            </Typography>
          </Grid>

          {reportImageDataByOthers.length === 0 &&
          <Grid item xs={12} container justifyContent="center" spacing={0}>
            <Typography variant="body2" component="p">
              データがありません
            </Typography>
          </Grid>
          }

          <Grid item xs={10} container justifyContent="center">
            <Grid
              container
              spacing={2}
              // justifyContent={reportImageDataByOthers.length === 1 ? "center" : "flex-start"}
            >
              {reportImageDataByOthers.map((data, index) => (
                <Grid key={data.id + index} item xs={12} sm={6} md={6} lg={3}>
                  <ImageCard data={data} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

    </div>
  );
};

ReportMagnify.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
  reportImageDataByOthers: state.report.reportImageDataByOthers,
});

const mapDispatchToProps = (dispatch) => ({
  // setPeriodType: (param) => dispatch(setPeriodType(param)),
});

export default connect(
  mapStateToProps
)(withStyles(null, { withTheme: true })(ReportMagnify));
