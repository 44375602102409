const SignInStyles = theme => ({
    card: {
      overflow: 'visible'
    },
    session: {
      position: 'relative',
      zIndex: 4000,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    background: {
      backgroundColor: '#e9ebee'
    },
    content: {
      padding: `40px ${theme.spacing()}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '100%',
      textAlign: 'center'
    },
    wrapper: {
      flex: 'none',
      maxWidth: '400px',
      width: '100%',
      margin: '52px auto'
    },
    avatar: {
      position: 'relative',
      display: 'block',
      margin: '-75px auto 0'
    },
    title: {
      fontSize: '150px',
      lineHeight: 1.2,
      fontWeight: 100,
      display: 'inline-table',
      position: 'relative',
      background: theme.palette.primary.main,
      color: '#fff',
      padding: `0 ${theme.spacing(3)}px`,
      borderRadius: '60px',
      cursor: 'pointer',
      margin: `0 0 ${theme.spacing()}px`,
      '&:after': {
        top: '100%',
        left: '50%',
        border: 'solid transparent',
        content: '""',
        height: 0,
        width: 0,
        position: 'absolute',
        pointerEvents: 'none',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderTopColor: theme.palette.primary.main,
        borderWidth: '8px',
        marginLeft: '-8px'
      }
    },
    subtitle: {
      fontSize: '32px',
      fontWeight: 900
    },
    fullWidth: {
      width: '100%',
      padding: `${theme.spacing(2)}px`
    },
    signInButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      borderRadius: '0px',
      boxShadow: 'none',
      marginTop: '20px',
      marginBottom: '20px',
      fontSize: '16px'
    },
    errorMessageDiv: {
      paddingBottom: '10px'
    },
    errorMessage: {
      color: 'red'
    },
    formMessage: {
      marginTop: '20px',
      marginBottom: '20px',
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '140%'
    },
    updateButton: {
      marginTop: '30px',
      backgroundColor: "#67c8a5 !important",
      color: '#ffffff',
      borderRadius: '0px',
      boxShadow: 'none',
      marginBottom: '20px',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: "#81d7b8 !important",
      },
    },
    pwTextField: {
      marginBottom: '20px'
    },
    logoImg: {
      paddingBottom: "16px",
    }
});

export default SignInStyles;
