const tagCategory = {
  "A": "ベース運用",
  "B": "ミッション（電柱接触）",
  "C": "ミッション（仮復旧）"
};

const seasonTag = [
  {
    "value": "A0",
    "label": "A0",
    "tip": "A0: なし＝A1-1～A2-3の目的以外で閲覧した結果、特に不良がなかったもの）"
  },
  {
    "value": "A1-1",
    "label": "A1-1",
    "tip": "A1-1: お客様要請＝出向回避（計画作業振替、設計依頼を含む）"
  },
  {
    "value": "A1-2",
    "label": "A1-2",
    "tip": "A1-2: お客様要請＝緊急性を確認したため、現場出向"
  },
  {
    "value": "A1-3",
    "label": "A1-3",
    "tip": "A1-3: お客様要請＝写真では正確に判定できないため、現場出向"
  },
  {
    "value": "A2-1",
    "label": "A2-1",
    "tip": "A2-1: 予防保全＝机上管理（計画作業振替、設計依頼を含む）"
  },
  {
    "value": "A2-2",
    "label": "A2-2",
    "tip": "A2-2: 予防保全＝緊急性を確認したため、現場出向"
  },
  {
    "value": "A2-3",
    "label": "A2-3",
    "tip": "A2-3: 予防保全＝写真では正確に判定できないため、現場出向"
  },
];

const missionTagB =  [
  {
    "value": "B1-1",
    "label": "B1-1",
    "tip": "B1-1: 電柱接触痕から、安全性を確認したため、出向回避"
  },
  {
    "value": "B1-2",
    "label": "B1-2",
    "tip": "B1-2: 電柱接触痕から、緊急性を確認したため、現場出向"
  },
  {
    "value": "B1-3",
    "label": "B1-3",
    "tip": "B1-3: 写真では正確に判定できないため、現場出向"
  },
];

const missionTagC =  [
  {
    "value": "C1-1",
    "label": "C1-1",
    "tip": "C1-1: 仮復旧箇所に段差が無いことを確認したため、出向回避"
  },
  {
    "value": "C1-2",
    "label": "C1-2",
    "tip": "C1-2: 仮復旧箇所に段差が有ることを確認したため、現場出向"
  },
  {
    "value": "C1-3",
    "label": "C1-3",
    "tip": "C1-3: 写真では正確に判定できないため、現場出向"
  },
];

// @todo: temporary data, this might be fetched from the SXI DB
const reportTag =  [
  {
    "value": "営巣情報",
    "label": "営巣情報",
    "tip": "営巣情報"
  },
  {
    "value": "台風情報",
    "label": "台風情報",
    "tip": "台風情報"
  },
  {
    "value": "樹木情報",
    "label": "樹木情報",
    "tip": "樹木情報"
  },
  {
    "value": "電柱接触情報",
    "label": "電柱接触情報",
    "tip": "電柱接触情報"
  },
  {
    "value": "電柱垂れ情報",
    "label": "電柱垂れ情報",
    "tip": "電柱垂れ情報"
  },
  {
    "value": "飛来物情報",
    "label": "飛来物情報",
    "tip": "飛来物情報"
  },
  {
    "value": "その他",
    "label": "その他",
    "tip": "その他"
  },
];

const reportTag2 =  [
  {
    "value": "対象無し",
    "label": "対象無し",
    "tip": " 対象物が存在しない"
  },
  {
    "value": "位置ズレ",
    "label": "位置ズレ",
    "tip": "対象物がマップ上の位置と大きくズレている"
  },
  {
    "value": "撮影困難",
    "label": "撮影困難",
    "tip": "対象物が撮影できる場所にない（私有地内、山間部など）"
  },
  {
    "value": "撮影危険",
    "label": "撮影危険",
    "tip": "撮影に危険が伴う場所に対象物がある（車道に乗り出さないと撮影できない場所など）"
  },
  {
    "value": "その他",
    "label": "その他",
    "tip": "その他"
  },
  {
    "value": "営巣",
    "label": "営巣",
    "tip": "電柱に鳥の巣が存在している"
  },
  {
    "value": "樹木",
    "label": "樹木",
    "tip": "電柱に植物が接触している"
  },
  {
    "value": "飛来物",
    "label": "飛来物",
    "tip": "電柱や電線に物が引っかかっている（布団・ハンガー・傘・レジ袋等）"
  },
  {
    "value": "番札",
    "label": "番札",
    "tip": "電柱に電柱番号プレートが付いていない、または破損している"
  },
  {
    "value": "電垂れ",
    "label": "電垂れ",
    "tip": "電線が垂れ下がっている、切れている、手に届く位置にある"
  },
  {
    "value": "換気孔破損",
    "label": "換気孔破損",
    "tip": "電柱周辺（電柱から半径約3ｍ）のアスファルトに1㎝以上の段差がある"
  },
  {
    "value": "換気孔段差",
    "label": "換気孔段差",
    "tip": "電柱の根本に1㎝以上の隙間や穴がある"
  },
  {
    "value": "電柱段差",
    "label": "電柱段差",
    "tip": "換気口が一部欠けている、破損している"
  },
  {
    "value": "電柱地際",
    "label": "電柱地際",
    "tip": "換気口が傾いている、または地面との間に1㎝以上の段差がある"
  },
  {
    "value": "番号",
    "label": "番号",
    "tip": "地上設備の番号がない、または表示が薄い"
  },
  {
    "value": "落書き",
    "label": "落書き",
    "tip": "地上設備に落書きがしてある"
  },
  {
    "value": "錆",
    "label": "錆",
    "tip": "地上設備が錆びている"
  },
];


export default {
  tagCategory,
  seasonTag,
  missionTagB,
  missionTagC,
  reportTag,
  reportTag2,
}
