import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import {
  setFilter,
  setReportFilter,
  getSavedSelectedTagDataByEmail,
  getHomeImageData,
  setCurrentPageNumber,
  getReportData,
} from "../../actions";
import TagConfig from "../../config/TagConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: 500,
      padding: 16,
      position: "relative", // Ensure the container is relative
      height: "100vh", // Ensure the container takes the full height
      boxSizing: "border-box", // Include padding in height
    },
    filter: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      paddingTop: theme.spacing(5),
    },
    filterItem: {
      marginTop: theme.spacing(4),
    },
    filterButtonContainer: {
      position: "absolute",
      bottom: 45,
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
    formControl: {
      margin: theme.spacing(1),
      width: "89%",
    },
    dataBtn: {
      minWidth: 200,
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
    },
    tagSelect: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontSize: 16,
      minWidth: 416,
      maxWidth: 416,
      "& .MuiInputLabel-root": {
        fontSize: "16px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "16px", // Customize input text size (if needed)
      },
    },
  })
);

const FilterDrawer = ({
  currentPage,
  periodType,
  toggleDrawer,
  anchor,
  setFilter,
  setReportFilter,
  filterStore,
  reportFilterStore,
  getSavedSelectedTagDataByEmail,
  getHomeImageData,
  setCurrentPageNumber,
  filterType,
  reportTagFilterData,
  getReportData,
}) => {
  const classes = useStyles();
  const [sort, setSort] = useState(`${filterStore.type}-${filterStore.order}`);
  const [signNumber, setSignNumber] = useState("");
  const [assetId, setAssetId] = useState("");
  const [tagData, setTagData] = useState([]);
  const [assetType, setAssetType] = useState("");
  const [manageGroup, setManageGroup] = useState({
    key: "all",
    value: [],
  });
  const sortPrefix = filterType === "photo" ? "撮影" : "報告";
  const tagLabel = filterType === "photo" ? "結果タグ" : "報告タグ";

  const toHalfWidth = (str) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
  };

  useEffect(() => {
    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.filterType = filterType;
    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  }, [currentPage]);

  useEffect(() => {
    setAssetId(filterType === "photo" ? filterStore.assetId : reportFilterStore.assetId);
  }, [filterStore.assetId, reportFilterStore.assetId]);

  useEffect(() => {
    setSignNumber(filterType === "photo" ? filterStore.signNumber : reportFilterStore.signNumber);
  }, [filterStore.signNumber, reportFilterStore.signNumber]);

  useEffect(() => {
    setAssetType(filterType === "photo" ? filterStore.assetType : reportFilterStore.assetType);
  }, [filterStore.assetType, reportFilterStore.assetType]);

  useEffect(() => {
    setManageGroup(filterType === "photo" ? filterStore.manageGroup : reportFilterStore.manageGroup);
  }, [filterStore.manageGroup, reportFilterStore.manageGroup]);

  useEffect(() => {
    if (filterType === "photo") {
      if (periodType === "season") {
        setTagData(TagConfig.seasonTag);
      } else {
        setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
      }
    } else if (filterType === "report") {
      if (reportTagFilterData.length > 0) {
        setTagData(reportTagFilterData);
      }
      // setTagData(TagConfig.reportTag2);
    }
  }, [periodType, filterType]);

  const handleSignNumberChange = (event) => {
    let value = event.target.value;
    // value = toHalfWidth(value);

    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.signNumber = value;

    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  };

  const handleAssetIdChange = (event) => {
    let value = event.target.value;
    // value = toHalfWidth(value);

    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.assetId = value;

    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  };

  const handleAssetTypeChange = (event) => {
    const value = event.target.value;
    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.assetType = value;
    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  };

  const handleManageGroupChange = (event) => {
    const value = event.target.value;
    let manageGroupArray = [];
    if (value === "NUMAZU") {
      manageGroupArray = [2092000, 2092100, 2092200];
    } else if (value === "IZUKITA") {
      manageGroupArray = [2092300, 2092600];
    }
    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.manageGroup = {
      key: value,
      value: manageGroupArray,
    };
    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  };

  const handleChange = (event) => {
    const filterValue = event.target.value.split("-");
    const filter = {
      type: filterValue[0],
      order: filterValue[1],
      tag: filterType === "photo" ? filterStore.tag : reportFilterStore.tag,
      filterType: filterType,
      assetId: filterType === "photo" ? filterStore.assetId : reportFilterStore.assetId,
      signNumber: filterType === "photo" ? filterStore.signNumber : reportFilterStore.signNumber,
      assetType: filterType === "photo" ? filterStore.assetType : reportFilterStore.assetType,
      manageGroup: filterType === "photo" ? filterStore.manageGroup : reportFilterStore.manageGroup,
    };
    setSort(event.target.value);
    if (filterType === "photo") {
      setFilter(filter);
    } else if (filterType === "report") {
      setReportFilter(filter);
    }
  };

  const fetchData = () => {
    if (filterStore.tag !== undefined && filterStore.tag.length > 0) {
      getSavedSelectedTagDataByEmail();
    } else {
      if (currentPage.includes("report")) {
        getReportData({
          pageNumber: 1,
        });
      } else {
        getHomeImageData({
          pageNumber: 1,
        });
      }
    }
    setCurrentPageNumber(1);
    toggleDrawer(anchor, false)();
  };

  const handleReset = () => {
    if (filterType === "photo") {
      setFilter({
        type: "occurred_datetime",
        order: "desc",
        tag: [],
        filterType: "photo",
        assetId: null,
        signNumber: null,
        assetType: "all",
        manageGroup: {
          key: "all",
          value: [],
        },
      });
    } else if (filterType === "report") {
      setReportFilter({
        type: "occurred_datetime",
        order: "desc",
        tag: [],
        filterType: "report",
        assetId: null,
        signNumber: null,
        assetType: "all",
        manageGroup: {
          key: "all",
          value: [],
        },
      });
    }
    setAssetId("");
    setSignNumber("");
    setSort("occurred_datetime-desc");
    setAssetType("");
    toggleDrawer(anchor, false)();
    setCurrentPageNumber(1);
    if (filterType === "photo") {
      getHomeImageData({
        pageNumber: 1,
      });
    } else {
      getReportData({
        pageNumber: 1,
      });
    }
  };

  const handleSelectTagChange = (tags) => {
    let copyFilter = filterType === "photo" ? { ...filterStore } : { ...reportFilterStore };
    copyFilter.tag = tags;
    if (filterType === "photo") {
      setFilter(copyFilter);
    } else if (filterType === "report") {
      setReportFilter(copyFilter);
    }
  };

  return (
    <div className={classes.container}>
      <IconButton
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(anchor, false)}
        disableRipple={true}
        disableFocusRipple={true}
      >
        <CloseIcon />
      </IconButton>
      絞り込み条件設定
      <br />
      <Grid
        container
        direction="column"
        justifyContent={"flex-start"}
        className={classes.filter}
      >
        <Grid item>
          <TextField
            id="signNumber"
            label="標識番号"
            variant="outlined"
            style={{ width: "89%", paddingLeft: 6 }}
            value={filterStore.signNumber}
            onChange={handleSignNumberChange}
          />
        </Grid>
        <Grid item className={classes.filterItem}>
          <TextField
            id="assetId"
            label="設備番号"
            variant="outlined"
            style={{ width: "89%", paddingLeft: 6 }}
            value={filterStore.assetId}
            onChange={handleAssetIdChange}
          />
        </Grid>
        <Grid item className={classes.filterItem}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-assetType-label">アセットタイプ</InputLabel>
            {periodType === "mission" && (
              <Select
                labelId="simple-select-assetType-label"
                id="simple-select-assetType"
                value={assetType}
                onChange={handleAssetTypeChange}
                label="アセットタイプ"
              >
                <MenuItem value="MISSION_ASSET">
                  全てのミッションアセット
                </MenuItem>
              </Select>
            )}
            {periodType === "season" && (
              <Select
                labelId="simple-select-assetType-label"
                id="simple-select-assetType"
                value={assetType}
                onChange={handleAssetTypeChange}
                label="アセットタイプ"
              >
                <MenuItem value="all">全て</MenuItem>
                <MenuItem value="POWER_POLE">電柱（全て）</MenuItem>
                <MenuItem value="POWER_POLE_HAS_PROTECTIVE_TUBE_ALL">
                  電柱（防護管付き・全て）
                </MenuItem>
                <MenuItem value="POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC">
                  電柱（防護管付き・写真あり）
                </MenuItem>
                <MenuItem value="GRATING">換気口</MenuItem>
                <MenuItem value="MAN_HOLE">マンホール</MenuItem>
                <MenuItem value="HAND_HOLE">ハンドホール</MenuItem>
                <MenuItem value="GROUND_EQUIPMENT-MS">
                  地上設備 (設備コード:MS)
                </MenuItem>
                <MenuItem value="GROUND_EQUIPMENT-PM">
                  地上設備 (設備コード:PM)
                </MenuItem>
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item className={classes.filterItem}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-assetType-label">
              配電保守グループ
            </InputLabel>
            {periodType === "season" && (
              <Select
                labelId="simple-select-assetType-label"
                id="simple-select-assetType"
                value={manageGroup.key}
                onChange={handleManageGroupChange}
                label="配電保守グループ"
              >
                <MenuItem value="all">全て</MenuItem>
                <MenuItem value="NUMAZU">沼津制御所配電保守グループ</MenuItem>
                <MenuItem value="IZUKITA">伊豆制御所伊豆北地域配電保守グループ</MenuItem>
              </Select>
            )}
          </FormControl>
        </Grid>
        <Grid item className={classes.filterItem}>
          {/* {tagData !== null && tagData.length > 0 && ( */}
            <Autocomplete
              multiple
              id="filter-tag-select"
              options={tagData}
              noOptionsText="データがありません"
              getOptionLabel={(option) => option.value || ""}
              getOptionSelected={(option, value) =>
                option.value.toString() === value.toString()
              }
              value={
                filterType === "photo" ?
                  filterStore.tag !== undefined && filterStore.tag.length > 0
                  ? tagData.filter((tag) =>
                      filterStore.tag.includes(tag.value.toString())
                    )
                  : []
                : reportFilterStore.tag !== undefined && reportFilterStore.tag.length > 0
                  ? tagData.filter((tag) =>
                      reportFilterStore.tag.includes(tag.value.toString())
                    )
                  : []
              }
              onChange={(event, newValue) => {
                if (newValue) {
                  const selectedValues = newValue.map((tag) =>
                    tag.value.toString()
                  );
                  handleSelectTagChange(selectedValues);
                } else {
                  handleSelectTagChange([]);
                }
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={option.value}
                    {...getTagProps({ index })}
                    style={{ backgroundColor: "#f3ee91", color: "black" }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={tagLabel}
                  className={classes.tagSelect}
                />
              )}
              renderOption={(option) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography variant="body1"><b>{option.label}</b> : {option.tip} ({option.count})</Typography>
                </div>
              )}
            />
          {/* )} */}
        </Grid>
        <Grid item className={classes.filterItem}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="filter-sort-label">ソート</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={sort}
              onChange={handleChange}
              label="ソート"
            >
              <MenuItem value="occurred_datetime-desc">
                {sortPrefix}日時 - 降順
              </MenuItem>
              <MenuItem value="occurred_datetime-asc">
                {sortPrefix}日時 - 昇順
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <div className={classes.filterButtonContainer}>
          <Button
            className={classes.dataBtn}
            variant="contained"
            color="primary"
            onClick={fetchData}
          >
            絞り込む
          </Button>
          <Button
            className={classes.dataBtn}
            variant="contained"
            color="default"
            onClick={handleReset}
          >
            リセット
          </Button>
        </div>
      </Grid>
    </div>
  );
};

FilterDrawer.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentPage: state.app.currentPage,
  periodType: state.app.periodType,
  filterStore: state.home.filter,
  reportFilterStore: state.report.filter,
  reportTagFilterData: state.report.reportTagFilterData,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (param) => dispatch(setFilter(param)),
  setReportFilter: (param) => dispatch(setReportFilter(param)),
  getSavedSelectedTagDataByEmail: () => dispatch(getSavedSelectedTagDataByEmail()),
  getHomeImageData: (param) => dispatch(getHomeImageData(param)),
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
  getReportData: (param) => dispatch(getReportData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(FilterDrawer));
