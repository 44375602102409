import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ReactImageMagnify from 'react-image-magnify';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from 'lodash';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      paddingTop: '20px',
      justifyContent: "center",
    },
    thumbnailSliderWrapper: {
      position: 'relative',
      width: 100,
      marginRight: 16,
    },
    thumbnailSlider: {
      width: 100,
      marginRight: 16,
      '.slick-list': {
        height: "440px",
        overflow: 'hidden',
      },
    },
    thumbnail: {
      width: '100%',
      cursor: 'pointer',
      border: '2px solid transparent',
      marginBottom: 8,
      '&:hover': {
        border: '2px solid #ccc',
      },
    },
    imageMagnify: {
      flex: 1,
      maxWidth: 1200,
      position: 'relative',
    },
    magnifyWrapper: {
      position: 'relative',
      display: 'flex',
      width: '1200px',
    },
    magnifyPlaceholder: {
      position: 'relative',
      top: 0,
      left: 10,
      width: '795px',
      maxWidth: 1200,
      border: '2px dashed #ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#333',
      fontSize: '14px',
      zIndex: 1,
      pointerEvents: 'none',
    },
    hidden: {
      display: 'none',
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
    arrow: {
      position: 'absolute',
      zIndex: 2,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '50%',
      padding: '5px',
      cursor: 'pointer',
      left: '33%',
    },
    arrowUp: {
      top: '-55px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    arrowDown: {
      bottom: '-40px',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  })
);

const CustomArrowUp = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <ExpandLessIcon className={`${classes.arrow} ${className}`} onClick={onClick} style={{marginTop: "-30px"}} />
  );
};

const CustomArrowDown = ({ className, onClick }) => {
  const classes = useStyles();
  return (
    <ExpandMoreIcon className={`${classes.arrow} ${className}`} onClick={onClick} />
  );
};

const ImageMagnify = ({ viewer }) => {
  const classes = useStyles();
  const sliderRef = useRef(null);
  const containerRef = useRef(null); // コンテナの参照を取得
  const [selectedImage, setSelectedImage] = useState([]);
  const [isZoomed, setIsZoomed] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (viewer.length > 0) {
      let imageUrls = {}, imageUrlsArray = [];
      _.each(viewer, function(photoData) {
        imageUrls.small = photoData.signedUrl;
        imageUrls.large = photoData.signedUrl;
        imageUrls.fileName = photoData.file_name;
        imageUrlsArray.push(imageUrls);
        imageUrls = {};
      });
      setImages(imageUrlsArray);
    }
  }, [viewer]);

  useEffect(() => {
    if (images.length > 0) {
      setSelectedImage(images[0]);

      const handleWheel = (e) => {
        e.preventDefault();
        if (e.deltaY < 0) {
          sliderRef.current.slickPrev();
        } else {
          sliderRef.current.slickNext();
        }
      };

      const containerElement = containerRef.current; // コンテナ要素を取得

      if (containerElement) {
        containerElement.addEventListener('wheel', handleWheel);
      }

      return () => {
        if (containerElement) {
          containerElement.removeEventListener('wheel', handleWheel);
        }
      };
    }
  }, [images]);

  const settings = {
    vertical: true,
    verticalSwiping: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    dots: false,
    arrows: false,
    focusOnSelect: true,
    prevArrow: <CustomArrowUp />,
    nextArrow: <CustomArrowDown />,
  };

  const handleThumbnailClick = (index) => {
    setSelectedImage(images[index]);
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const openImageInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const downloadImage = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const urlObject = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlObject;
      link.download = selectedImage.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL object after download
      window.URL.revokeObjectURL(urlObject);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  return (
    <div ref={containerRef} className={classes.container}>
      <div className={classes.thumbnailSliderWrapper}>
        <CustomArrowUp onClick={() => sliderRef.current.slickPrev()} />
        <div className={classes.thumbnailSlider} style={{ height: '450px', overflow: 'hidden' }}>
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
              <div key={index} onClick={() => handleThumbnailClick(index)}>
                <img
                  src={image.small}
                  alt={`Thumbnail ${index}`}
                  className={classes.thumbnail}
                />
              </div>
            ))}
          </Slider>
        </div>
        <CustomArrowDown onClick={() => sliderRef.current.slickNext()} />
      </div>
      <div className={classes.imageMagnify}>
        <div className={classes.magnifyWrapper}>
          <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: 'Selected Image',
                  isFluidWidth: false,
                  width: 300,
                  height: 450,
                  src: selectedImage.small,
                },
                largeImage: {
                  src: selectedImage.large,
                  width: 3000,
                  height: 4200,
                },
                enlargedImageContainerDimensions: {
                  width: "265%",
                  height: "100%",
                },
              }}
            />
          </div>
          {!isZoomed && (
            <div className={classes.magnifyPlaceholder}>
              画像にマウスを合わせると拡大が表示されます
            </div>
          )}
        </div>
        <Button
          className={classes.dataBtn}
          variant="contained"
          onClick={() => openImageInNewTab(selectedImage.large)}
        >
          元画像を開く
        </Button>
        {/* <Button
          className={classes.dataBtn}
          variant="contained"
          onClick={() => downloadImage(selectedImage.large)}
          // style={{marginLeft: 8, paddingTop: 4}}
        >
          画像をダウンロード
        </Button> */}
      </div>
    </div>
  );
};

ImageMagnify.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
});

const mapDispatchToProps = (dispatch) => ({
  // setPeriodType: (param) => dispatch(setPeriodType(param)),
});

export default connect(
  mapStateToProps
)(withStyles(null, { withTheme: true })(ImageMagnify));
