import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alpha } from "@material-ui/core/styles";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import * as Var from "../../styles/variables";
import FilterDrawer from "../../components/Filter/FilterDrawer";
import {
  setCurrentPageNumber,
  setFilterDrawerState,
  setFilterType,
  setDateRange,
  setMinMaxDateRange,
  getSavedSelectedTagDataByEmail,
  getHomeImageData,
  getReportData,
} from "../../actions";
import moment from "moment";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: alpha("#fafafa", 1),
      marginTop: theme.spacing(-2),
      zIndex: 1000,
      paddingTop: 0,
      paddingLeft: 8,
      marginTop: -12,
    },
    container: {
      display: "flex",
      width: 300,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      alignItems: "baseline",
    },
    calendarSection: {
      marginTop: "10px",
    },
    delimiter: {
      paddingTop: theme.spacing(1),
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      color: Var.btnLabelColor,
    },
    sectionCalendar: {
      display: "flex",
    },
    filterBtn: {
      marginTop: theme.spacing(1.2),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
    },
  })
);

const Calendar = ({
  currentPage,
  setDateRange,
  dateRange,
  minMaxDateRange,
  setCurrentPageNumber,
  getSavedSelectedTagDataByEmail,
  getHomeImageData,
  setFilterDrawerState,
  periodType,
  seasonData,
  missionData,
  selectedSeason,
  setMinMaxDateRange,
  selectedMission,
  filterStore,
  filterType,
  setFilterType,
  getReportData,
}) => {
  const anchor = "right";
  const classes = useStyles();
  const [error, setError] = useState([null, null]);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    if (currentPage.includes("home")) {
      setFilterType("photo");
    } else {
      setFilterType("report");
    }
  }, [currentPage]);

  useEffect(() => {
    if (periodType === "season") {
      const selecteSeasonData = seasonData.find(
        (season) => season.seasonId === selectedSeason
      );
      if (selecteSeasonData) {
        const { seasonStart, seasonEnd } = selecteSeasonData;
        // Set the date range based on the selected mission's dates
        setDateRange([
          moment(seasonStart).toDate(),
          moment(seasonEnd).toDate(),
        ]);
        setMinMaxDateRange([seasonStart, seasonEnd]);
      }
    } else {
      // Mission
      const selectedMissionData = missionData.find(
        (mission) => mission.missionId === selectedMission
      );
      if (selectedMissionData) {
        const { missionStart, missionEnd } = selectedMissionData;
        // Set the date range based on the selected mission's dates
        setDateRange([
          moment(missionStart).toDate(),
          moment(missionEnd).toDate(),
        ]);
        setMinMaxDateRange([missionStart, missionEnd]);
      }
    }
  }, [selectedSeason, selectedMission]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setFilterDrawerState(open);
  };

  const fetchData = () => {
    if (filterStore.tag.length > 0) {
      getSavedSelectedTagDataByEmail();
    } else {
      if (currentPage.includes("report")) {
        getReportData({
          pageNumber: 1,
        });
      } else {
        getHomeImageData({
          pageNumber: 1,
        });
      }
    }
    setCurrentPageNumber(1);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} direction="row">
        <Grid item>
          <DateRangePicker
            startText="開始"
            endText="終了"
            disableHighlightToday={true}
            showToolbar={false}
            allowSameDateSelection={true}
            minDate={minMaxDateRange[0] !== null ? minMaxDateRange[0] : null}
            maxDate={minMaxDateRange[1] !== null ? minMaxDateRange[1] : null}
            value={dateRange}
            onChange={(newValue) => {
              setDateRange(newValue);
              // setPageNumber(1);
            }}
            onError={([startReason, endReason], [start, end]) => {
              if (startReason === "invalidRange") {
                setError([null, null]);
                return;
              }
              setError([startReason, endReason]);
            }}
            renderInput={(startProps, endProps) => (
              <div className={classes.container}>
                <TextField
                  id="standard-basic"
                  {...startProps}
                  error={Boolean(error[0])}
                  size="small"
                  helperText=""
                />
                <DateRangeDelimiter>
                  <span className={classes.delimiter}>~</span>
                </DateRangeDelimiter>
                <TextField
                  id="standard-basic"
                  {...endProps}
                  error={Boolean(error[0])}
                  size="small"
                  helperText=""
                />
              </div>
            )}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.dataBtn}
            variant="contained"
            color="primary"
            onClick={() => fetchData()}
          >
            表示
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.filterBtn}
            variant="contained"
            color="default"
            onClick={toggleDrawer(anchor, true)}
          >
            絞り込み
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <FilterDrawer
              toggleDrawer={toggleDrawer}
              anchor={anchor}
              filterType={filterType}
            />
          </Drawer>
        </Grid>
      </Grid>
    </div>
  );
};

Calendar.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentPage: state.app.currentPage,
  dateRange: state.app.dateRange,
  minMaxDateRange: state.app.minMaxDateRange,
  filterDrawerState: state.app.filterDrawerState,
  periodType: state.app.periodType,
  seasonData: state.app.seasonData,
  missionData: state.app.missionData,
  selectedSeason: state.app.selectedSeason,
  selectedMission: state.app.selectedMission,
  filterStore: state.home.filter,
  filterType: state.app.filterType,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
  setDateRange: (param) => dispatch(setDateRange(param)),
  setMinMaxDateRange: (param) => dispatch(setMinMaxDateRange(param)),
  setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
  setFilterType: (param) => dispatch(setFilterType(param)),
  getSavedSelectedTagDataByEmail: () => dispatch(getSavedSelectedTagDataByEmail()),
  getHomeImageData: (param) => dispatch(getHomeImageData(param)),
  getReportData: (param) => dispatch(getReportData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Calendar));
