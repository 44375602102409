// Icons
import HomeIcon from '@material-ui/icons/Home';
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import AssignmentIcon from "@material-ui/icons/Assignment";

// Pages
import {
  Home,
  Report,
  Viewer,
  ReportViewer,
} from './pages';

export default {
  items: [
    {
      path: '/home',
      name: '撮影',
      key: 'home',
      type: 'link',
      icon: CameraAltIcon,
      component: Home,
      sideBar: true,
    },
    {
      path: '/report',
      name: '報告',
      key: 'report',
      type: 'link',
      icon: AssignmentIcon,
      component: Report,
      sideBar: true,
    },
    {
      path: '/viewer/:pid',
      name: 'ビューワー',
      key: 'view',
      type: 'link',
      icon: HomeIcon,
      component: Viewer,
      sideBar: false,
    },
    {
      path: '/report-viewer/:pid',
      name: '報告ビューワー',
      key: 'view',
      type: 'link',
      icon: HomeIcon,
      component: ReportViewer,
      sideBar: false,
    },
  ]
};
