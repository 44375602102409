import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Tooltip, Checkbox, IconButton, Chip } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Paper from "@material-ui/core/Paper";
import Pagination from "@material-ui/lab/Pagination";
import HelpIcon from "@material-ui/icons/Help";
import { setCurrentPageNumber, setSelectedTag } from "../../actions";
import _ from "lodash";
import TagConfig from "../../config/TagConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    tableWrapper: {
      width: "100%",
    },
    tableWrapperSlim: {
      width: "89%",
    },
    table: {
      width: "100%",
    },
    tableHead: {
      height: 40,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 126,
      "&:hover": {
        backgroundColor: theme.palette.action.hover, // Use a light grey color on hover
      },
    },
    tableRowNone: {
      height: 126,
    },
    noDataCell: {
      textAlign: "center",
      padding: theme.spacing(3),
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    thumb: {
      width: 80,
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    imagePopup: {
      position: "fixed", // Change to fixed so it stays within the viewport
      border: "1px solid #ccc",
      backgroundColor: "#fff",
      padding: theme.spacing(1),
      boxShadow: theme.shadows[3],
      zIndex: 1000,
    },
    tagUnSelect: {
      marginTop: theme.spacing(-2.6),
      fontSize: 9,
      "& .MuiInputLabel-root": {
        fontSize: "14px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "14px", // Customize input text size (if needed)
      },
    },
    tagSelect: {
      minWidth: 300,
      maxWidth: 300,
      marginTop: theme.spacing(-2.6),
      "& .MuiInputLabel-root": {
        fontSize: "14px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "14px", // Customize input text size (if needed)
      },
    },
    iconTooltip: {
      fontSize: 18,
      marginTop: -4,
      marginLeft: 4,
    },
    tagWrapper: {
      display: "flex",
    },
    saveButton: {
      fontWeight: "medium",
      height: 30,
      marginTop: -4,
      marginLeft: 16,
      paddingTop: 8,
    },
    tooltip: {
      fontSize: "12px", // フォントサイズを小さく
      paddingTop: "8px", // Tooltip の余白調整
    },
    icon: {
      paddingTop: "4px", // アイコンの位置調整
    },
    noImageBox: {
      all: "unset",
      width: "90px",  // 画像と同じサイズに調整
      height: "90px",
      backgroundColor: "#d9d3d3",  // 画像のグレーに近い色
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#000",  // テキストの色を薄めに
      fontSize: "14px",
      borderRadius: "6px", // 角丸の調整（適宜変更）
      margin: "auto",
      textDecoration: "none"
    }
  })
);

const ReportTable = ({
  periodType,
  setCurrentPageNumber,
  filteredTotal,
  setSelectedTag,
  handleImageSelect,
  open,
  downloadImage,
  setDownloadImage,
  filterType,
  reportData,
  activeDownload,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const itemsPerPage = 10;
  const popupWidth = 300;
  const popupHeight = 300;

  useEffect(() => {
    if (typeof filteredTotal === "number") {
      setTotalAssets(filteredTotal);
    }
  }, [filteredTotal]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
  }, [periodType]);

  useEffect(() => {
    const dataForTable = reportData;
    if (dataForTable.length > 0) {
      let imageDataObj = {},
        imageArray = [];
      _.each(dataForTable, function (data) {
        imageDataObj.reportId = data.reportId;
        imageDataObj.signNumber = data.signNumber;
        imageDataObj.signName = data.signName;
        imageDataObj.name = data.name;
        imageDataObj.placeCode = data.placeCode;
        imageDataObj.assetNum = data.assetNum;
        imageDataObj.fileName = data.fileName;
        imageDataObj.tag = data.tag;
        imageDataObj.tagTip = data.tagTip;
        imageDataObj.user = data.user;
        imageDataObj.fileUrl = data.fileUrl;
        const dateObj = new Date(data.occurred_datetime);
        imageDataObj.dateTime = dateObj
          .toISOString()
          .slice(0, 19)
          .replace("T", " ");
        imageArray.push(imageDataObj);
        imageDataObj = {};
      });
      setTableData(imageArray);
    } else {
      setTableData([]);
      setTotalAssets(0);
    }
  }, [reportData, filterType]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setCurrentPageNumber(value);
  };

  const getViewUrlView = (reportId) => `/report-viewer/${reportId}`;

  const pageCount = Math.ceil(totalAssets / itemsPerPage);

  const handleMouseEnter = (imageUrl, e) => {
    setHoveredImage(imageUrl);
    setHoverPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    setHoverPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const calculatePopupTop = (y) => {
    const viewportHeight = window.innerHeight;
    const top = y - popupHeight / 2;
    if (top < 0) return 0;
    if (top + popupHeight > viewportHeight) return viewportHeight - popupHeight;
    return top;
  };

  const calculatePopupLeft = (x) => {
    const viewportWidth = window.innerWidth;
    const left = x + 20;
    if (left + popupWidth > viewportWidth) return viewportWidth - popupWidth;
    return left;
  };

  const handleSelectTagChange = (tags, row) => {
    const savingTagData = {
      tags: tags, // Array of selected tags
      fileName: row.fileName, //row.fileName
    };
    console.log("savingTagData :", savingTagData);
    setSelectedTag(savingTagData);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        className={open ? classes.tableWrapperSlim : classes.tableWrapper}
      >
        <Grid item className={classes.filterItem}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.stickyHeader}>
                  {activeDownload && (
                    <TableCell align="center">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Checkbox
                          checked={
                            downloadImage.length > 0 &&
                            downloadImage.length === tableData.length
                          } // 全選択判定
                          indeterminate={
                            downloadImage.length > 0 &&
                            downloadImage.length < tableData.length
                          } // 部分選択時
                          onChange={(e) => {
                            if (e.target.checked) {
                              // 全選択
                              setDownloadImage(
                                tableData.map((row) => row.fileUrl)
                              );
                            } else {
                              // リセット
                              setDownloadImage([]);
                            }
                          }}
                          inputProps={{ "aria-label": "select all" }}
                        />
                        <Tooltip
                          title={
                            <div>
                              一括ダウンロード選択
                              <br />
                              <small>※最大10件まで選択可能です</small>
                            </div>
                          }
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <HelpIcon fontSize="small" className={classes.icon} />
                        </Tooltip>
                      </div>
                    </TableCell>
                  )}
                  <TableCell className={classes.thumb}></TableCell>
                  <TableCell align="center">標識番号</TableCell>
                  <TableCell align="center">標識名</TableCell>
                  <TableCell align="center">設備番号</TableCell>
                  <TableCell align="center">スキーマ<br />番号</TableCell>
                  <TableCell align="center">報告タグ</TableCell>
                  <TableCell align="center">報告者</TableCell>
                  <TableCell align="center">報告日時</TableCell>
                  <TableCell align="center">詳細</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 &&
                  tableData.map((row, index) => (
                    <TableRow className={classes.tableRow} key={index}>
                      {activeDownload && (
                        <TableCell style={{ width: "2%" }} align="center">
                          <Checkbox
                            checked={downloadImage.includes(row.fileUrl)}
                            onChange={(e) => handleImageSelect(e, row.fileUrl)}
                            inputProps={{
                              "download-image": "primary checkbox",
                            }}
                            disabled={downloadImage.length >= 10}
                          />
                        </TableCell>
                      )}
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "10%" }}
                      >
                        {row.fileUrl &&
                        <Link to={getViewUrlView(row.reportId)} target="_blank">
                          <img
                            src={row.fileUrl}
                            height="90"
                            width="90"
                            onMouseEnter={(e) =>
                              handleMouseEnter(row.fileUrl, e)
                            }
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              borderRadius: 6,
                              opacity: row.fileName == undefined ? 0 : 1,
                              transition: "opacity 0.5s ease-in-out",
                            }}
                          />
                        </Link>
                        }
                        {!row.fileUrl &&
                          <div className={classes.noImageBox}>
                            No Image
                          </div>
                        }
                      </TableCell>
                      <TableCell style={{ width: "7%" }} align="center">
                        {row.signNumber}
                      </TableCell>
                      <TableCell style={{ width: "7%" }} align="center">
                        {row.signName}
                      </TableCell>
                      <TableCell style={{ width: "7%" }} align="center">
                        {row.name}
                      </TableCell>
                      <TableCell style={{ width: "7%" }} align="center">
                        {row.placeCode}
                      </TableCell>
                      <TableCell style={{ width: "30%" }} align="left">
                        {tagData !== null && tagData.length > 0 && (
                          <div className={classes.tagWrapper}>
                            {row.tag.length > 0 &&
                              row.tag.map((tag, index) => (
                                <Tooltip
                                  title={tag == "その他"
                                    ? row.tagTip
                                    : row.tagTip[index]
                                  }
                                  placement="top"
                                >
                                  <Chip
                                    key={index}
                                    style={{
                                      backgroundColor: "#ffed6f",
                                      color: "black",
                                    }}
                                    size="small"
                                    label={tag}
                                  ></Chip>
                                </Tooltip>
                              ))}
                          </div>
                        )}
                      </TableCell>
                      <TableCell style={{ width: "20%" }} align="center">
                        {row.user}
                      </TableCell>
                      <TableCell style={{ width: "20%" }} align="center">
                        {row.dateTime}
                      </TableCell>
                      <TableCell>
                        <Link to={getViewUrlView(row.reportId)} target="_blank">
                          <IconButton>
                            <OpenInNewIcon fontSize="small" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                {tableData.length === 0 && (
                  <TableRow className={classes.tableRowNone}>
                    <TableCell colSpan={9} className={classes.noDataCell}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {tableData.length > 0 && (
          <Grid item>
            <div className={classes.pagination}>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
              />
            </div>
          </Grid>
        )}
      </Grid>
      {hoveredImage && (
        <div
          className={classes.imagePopup}
          style={{
            top: calculatePopupTop(hoverPosition.y),
            left: calculatePopupLeft(hoverPosition.x),
          }}
        >
          <img src={hoveredImage} alt="Preview" width={300} />
        </div>
      )}
    </>
  );
};

ReportTable.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  periodType: state.app.periodType,
  filterType: state.app.filterType,
  reportData: state.report.reportData,
  filteredTotal: state.report.filteredTotal,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
  setSelectedTag: (param) => dispatch(setSelectedTag(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ReportTable));
