import { HOME } from "../constants";

const initialState = {
  totalAssetStats: {
    denchu: "-",
    hh: "-",
    mh: "-",
  },
  homeImageData: [],
  totalImageCount: "--",
  currentPageNumber: 1,
  filteredTotal: "--",
  filter: {
    type: "occurred_datetime",
    order: "desc",
    tag: [],
    filterType: "photo",
    assetId: null,
    signNumber: null,
    assetType: "all",
    manageGroup: {
      key: "all",
      value: [],
    }
  },
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.SET_TOTAL_STATS_DATA:
      return {
        ...state,
        totalAssetStats: action.payload,
      };
    case HOME.SET_HOME_IMAGE_DATA:
      return {
        ...state,
        homeImageData: action.payload,
      };
    case HOME.SET_TOTAL_IMAGE_COUNT:
      return {
        ...state,
        totalImageCount: action.payload,
      };
    case HOME.SET_CURRENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    case HOME.SET_FILTERED_TOTAL:
      return {
        ...state,
        filteredTotal: action.payload,
      };
    case HOME.SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}
