const manageGroupLabel = {
  "NUMAZU": "沼津制御所配電保守グループ",
  "IZUKITA": "伊豆制御所伊豆北地域配電保守グループ",
};

// const reportTagSort = [
//   "対象無し",
//   "位置ズレ",
//   "撮影困難",
//   "営巣",
//   "樹木",
//   "飛来物",
//   "番札",
//   "電柱銘板",
//   "電垂れ",
//   "電柱段差",
//   "電柱地際陥没",
//   "電柱足場ボルト受口",
//   "換気孔破損",
//   "換気孔段差",
//   "番号",
//   "落書き",
//   "錆",
//   "撮影危険",
//   "その他",
// ];

// TEPCO order
const reportTagSort = [
  "樹木",
  "営巣",
  "飛来物",
  "電垂れ",
  "電柱地際陥没",
  "電柱段差",
  "落書き",
  "錆",
  "その他",
  "番札",
  "番号",
  "換気孔破損",
  "換気孔段差",
  "撮影危険",
  "撮影困難",
  "位置ズレ",
  "対象無し",
  "電柱銘板",
  "電柱足場ボルト受口",
];

export default {
  manageGroupLabel,
  reportTagSort,
}
