import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { APP, ENDPOINT } from "../constants";

import {
    setPageTopLoader,
    setSeasonData,
    setMissionData,
    setTagSavingLoader,
    setSelectedTag,
    setCommentSavingLoader,
    setAssetComment,
} from '../actions';

import {
    getJwtToken,
    getDataFromSxi,
    getDataFromKpiDash,
} from '../api';

// export const appLocale = state => state.app.locale;
export const startDate = state => state.app.dateRange[0];
export const endDate = state => state.app.dateRange[1];
export const selectedTag = state => state.app.selectedTag;
export const assetComment = state => state.app.assetComment;

const getLocalStorageItem = (key) => {
  return localStorage.getItem(key);
};

export function* handleSeasonData() {
  try {
    const jwtToken = yield call(getJwtToken);
    let loading = "loading";
    let dateFrom = yield select(startDate);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format("YYYY-MM-DD");
    let dateTo = yield select(endDate);
    dateTo = moment(dateTo).tz("Asia/Tokyo").format("YYYY-MM-DD");

    const sqlQueryGetSeason = {
      // query: `
      //   SELECT gs.id           AS 'seasonId'
      //       ,sn.official_name  AS 'seasonName'
      //       ,gt.start_datetime AS 'seasonStart'
      //       ,gt.end_datetime   AS 'seasonEnd'
      //   FROM game_space gs
      //   INNER JOIN game_term gt ON gt.id = gs.game_term_id
      //   INNER JOIN season s ON gs.game_term_id = s.id
      //   INNER JOIN season_name sn ON sn.season_id = s.id AND sn.language_code = 'JA'
      //   ORDER BY gt.start_datetime DESC
      // `,
      query: `
        SELECT gs.id           AS 'seasonId'
            ,sn.official_name  AS 'seasonName'
            ,gt.start_datetime AS 'seasonStart'
            ,gt.end_datetime   AS 'seasonEnd'
        FROM game_space gs
        INNER JOIN game_term gt ON gt.id = gs.game_term_id
        INNER JOIN season_name sn ON sn.season_id = gs.id AND sn.language_code = 'JA'
        ORDER BY gt.start_datetime DESC
      `,
    };
    const [dataSeasonDataTemp] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetSeason,
          ENDPOINT.GET_DATA_API
        ),
      ]);

    const seasonData = JSON.parse(dataSeasonDataTemp.body);
    let seasons = [], seasonObj = {};
    _.each(seasonData, function(s) {
      if (s.seasonId !== "clxvw56es00005soo28mj4jfs" &&
        s.seasonId !== "cm46zidp10001bpp63r3cfhwx" && // akita 1
        s.seasonId !== "cm64mqm94000br3n0esu8c0n4" && // kitakanto
        s.seasonId !== "cm46zidp30003bpp6cxq101mo" && // akita 2
        s.seasonId !== "cm64mqm930009r3n01z5xbr5d" // numazu5
      ) {
        seasonObj.seasonId = s.seasonId;
        seasonObj.seasonName = s.seasonName;
        seasonObj.seasonStart = moment(s.seasonStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasonObj.seasonEnd = moment(s.seasonEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasons.push(seasonObj);
        seasonObj = {};
      }
    });
    console.log("LOG seasons:", seasons);
    yield put(setSeasonData(seasons));
  } catch (error) {
    console.log(error);
  }
}

export function* handleMissionData() {
  try {
    const jwtToken = yield call(getJwtToken);
    let loading = "loading";
    let dateFrom = yield select(startDate);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format("YYYY-MM-DD");
    let dateTo = yield select(endDate);
    dateTo = moment(dateTo).tz("Asia/Tokyo").format("YYYY-MM-DD");

    const sqlQueryGetMission = {
      query: `
        SELECT gs.id          AS 'missionId'
            ,mn.name           AS 'missionName'
            ,gt.start_datetime AS 'missionStart'
            ,gt.end_datetime   AS 'missionEnd'
        FROM game_space gs
        INNER JOIN game_term gt
        ON gt.id = gs.game_term_id
        INNER JOIN mission m
        ON gs.id = m.id
        INNER JOIN mission_name mn
        ON mn.mission_id = m.id AND mn.language_code = 'JA'
        ORDER BY gt.start_datetime ASC
      `,
    };
    const [dataMissionDataTemp /*dataTotalHHTemp, dataTotalMHTemp*/] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetMission,
          ENDPOINT.GET_DATA_API
        ),
      ]);

    const missionData = JSON.parse(dataMissionDataTemp.body);
    let missions = [], missionObj = {};
    _.each(missionData, function(m) {
      if (m.missionId !== "cm0zde85z000407506byxr3bu" &&
        m.missionName !== "京都スタンプラリー" &&
        m.missionName.indexOf("動作確認") === -1 &&
        m.missionName.indexOf("建物撮影") === -1
      ) {
        missionObj.missionId = m.missionId;
        missionObj.missionName = m.missionName;
        missionObj.missionStart = moment(m.missionStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        missionObj.missionEnd = moment(m.missionEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        missions.push(missionObj);
        missionObj = {};
      }
    });
    missions.reverse();
    yield put(setMissionData(missions));
  } catch (error) {
    console.log(error);
  }
}

export function* handleSetSavedTagData() {
  try {
    const env = process.env.REACT_APP_ENV;
    const jwtToken = yield call(getJwtToken);
    yield put(setTagSavingLoader(true));
    const savingTag = yield select(selectedTag);

    const now = new Date();
    const utcDateTime = now.toISOString().slice(0, 19).replace('T', ' ');
    const fileName = savingTag.fileName; //savingTag.fileName.split(".")[0];
    const objectKey = savingTag.object_key;
    const occurredDatetime = savingTag.occurred_datetime.slice(0, 19).replace('T', ' ');
    const gameSpaceId = savingTag.game_space_id;
    const gameSpaceType = savingTag.game_space_type;
    const reviewRequestId = savingTag.review_request_id;
    const photoOfPowerAssetId = savingTag.photo_of_power_asset_id;
    const placeCode = savingTag.place_code;
    const signNumber = savingTag.sign_number;
    const equipmentNumber = savingTag.equipment_number;
    const playerId = savingTag.player_id;
    const playerName = savingTag.player_name;
    const tagArryString = JSON.stringify(savingTag.tags);
    const tagCommaString = savingTag.tags.map(String).join(',');
    const username = yield call(getLocalStorageItem, 'username');
    const email = yield call(getLocalStorageItem, 'email');

    const table = env === 'prod' ? "PicTreeViewerTagProd" : "PicTreeViewerTagDev";
    const sqlQueryGetTag = {
      query: `
        SELECT * FROM ${table} WHERE user_email = '${email}' AND file_name = '${fileName}';
      `,
      type: "select"
    };

    const [getTagDataTemp] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetTag,
          ENDPOINT.GET_DATA_KPIDASH_API
        ),
      ]);

    if (getTagDataTemp.length > 0) {
      // UPDATE
      const sqlQueryUpdateTag = {
        query: `
          UPDATE ${table}
          SET tag_array = '${tagArryString}', tag_flat = '${tagCommaString}', logDate = '${utcDateTime}'
          WHERE user_email = '${email}' AND file_name = '${fileName}';
        `,
        type: "write"
      };
      //console.log("LOG sqlQueryUpdateTag:", sqlQueryUpdateTag.query);
      const [updateTagDataTemp] =
        yield all([
          call(
            getDataFromKpiDash,
            sqlQueryUpdateTag,
            ENDPOINT.GET_DATA_KPIDASH_API
          ),
        ]);
      console.log("LOG updateTagDataTemp:", updateTagDataTemp);
    } else {
      // INSERT
      const sqlQuerySetTag = {
        query: `
          INSERT INTO ${table}
          (user_id, user_email, file_name, object_key, occurred_datetime, game_space_id, game_space_type, review_request_id, photo_of_power_asset_id, place_code, sign_number, equipment_number, player_id, player_name, tag_array, tag_flat, private, logDate) VALUES ('${username}', '${email}', '${fileName}', '${objectKey}', '${occurredDatetime}', '${gameSpaceId}', '${gameSpaceType}','${reviewRequestId}', '${photoOfPowerAssetId}', '${placeCode}', '${signNumber}','${equipmentNumber}', '${playerId}', '${playerName}', '${tagArryString}', '${tagCommaString}', 0, '${utcDateTime}');
        `,
        type: "write"
      };
      console.log("LOG sqlQuerySetTag:", sqlQuerySetTag.query);
      const [setTagDataTemp] =
        yield all([
          call(
            getDataFromKpiDash,
            sqlQuerySetTag,
            ENDPOINT.GET_DATA_KPIDASH_API
          ),
        ]);
      console.log("LOG setTagDataTemp:", setTagDataTemp);
      // yield put(setSelectedTag([])); // Rset
    }
    yield put(setTagSavingLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetSavedTagDataByFileName(action) {
  try {
    const env = process.env.REACT_APP_ENV;
    const email = yield call(getLocalStorageItem, 'email');
    const fileName = action.payload;
    const table = env === 'prod' ? "PicTreeViewerTagProd" : "PicTreeViewerTagDev";
    const sqlQueryGetTag = {
      query: `
        SELECT * FROM ${table} WHERE user_email = '${email}' AND file_name = '${fileName}';
      `,
      type: "select"
    };
    const [getTagDataTemp] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetTag,
          ENDPOINT.GET_DATA_KPIDASH_API
        ),
      ]);
    if (getTagDataTemp.length > 0) {
      const savedTagData = {
        fileName: getTagDataTemp[0][3]["stringValue"], //row.fileName
        tags: JSON.parse(getTagDataTemp[0][15]["stringValue"]),  // Array of selected tags
      }
      yield put(setSelectedTag(savedTagData)); // Fill up
    }
    yield put(setTagSavingLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleSetSavedCommentData() {
  try {
    const env = process.env.REACT_APP_ENV;
    yield put(setCommentSavingLoader(true));
    const savingComment = yield select(assetComment);

    const now = new Date();
    const utcDateTime = now.toISOString().slice(0, 19).replace('T', ' ');
    const fileName = savingComment.fileName;
    const objectKey = savingComment.object_key;
    const occurredDatetime = savingComment.occurred_datetime.slice(0, 19).replace('T', ' ');
    const gameSpaceId = savingComment.game_space_id;
    const gameSpaceType = savingComment.game_space_type;
    const reviewRequestId = savingComment.review_request_id;
    const photoOfPowerAssetId = savingComment.photo_of_power_asset_id;
    const placeCode = savingComment.place_code;
    const signNumber = savingComment.sign_number;
    const equipmentNumber = savingComment.equipment_number;
    const playerId = savingComment.player_id;
    const playerName = savingComment.player_name;
    const comment = savingComment.comment;
    const username = yield call(getLocalStorageItem, 'username');
    const email = yield call(getLocalStorageItem, 'email');

    const table = env === 'prod' ? "PicTreeViewerCommentProd" : "PicTreeViewerCommentDev";
    const sqlQueryGetComment = {
      query: `
        SELECT * FROM ${table} WHERE user_email = '${email}' AND file_name = '${fileName}';
      `,
      type: "select"
    };

    const [getCommentDataTemp] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetComment,
          ENDPOINT.GET_DATA_KPIDASH_API
        ),
      ]);

    if (getCommentDataTemp.length > 0) {
      // UPDATE
      const sqlQueryUpdateComment = {
        query: `
          UPDATE ${table}
          SET comment = '${comment}', logDate = '${utcDateTime}'
          WHERE user_email = '${email}' AND file_name = '${fileName}';
        `,
        type: "write"
      };
      const [updateCommentDataTemp] =
        yield all([
          call(
            getDataFromKpiDash,
            sqlQueryUpdateComment,
            ENDPOINT.GET_DATA_KPIDASH_API
          ),
        ]);
    } else {
      // INSERT
      const sqlQuerySetComment = {
        query: `
          INSERT INTO ${table}
          (user_id, user_email, file_name, object_key, occurred_datetime, game_space_id, game_space_type, review_request_id, photo_of_power_asset_id, place_code, sign_number, equipment_number, player_id, player_name, comment, private, logDate) VALUES ('${username}', '${email}', '${fileName}', '${objectKey}', '${occurredDatetime}', '${gameSpaceId}', '${gameSpaceType}','${reviewRequestId}', '${photoOfPowerAssetId}', '${placeCode}', '${signNumber}','${equipmentNumber}', '${playerId}', '${playerName}', '${comment}', 0, '${utcDateTime}');
        `,
        type: "write"
      };
      console.log("LOG sqlQuerySetComment:", sqlQuerySetComment.query);
      const [setCommentDataTemp] =
        yield all([
          call(
            getDataFromKpiDash,
            sqlQuerySetComment,
            ENDPOINT.GET_DATA_KPIDASH_API
          ),
        ]);
      console.log("LOG setCommentDataTemp:", setCommentDataTemp);
    }
    yield put(setCommentSavingLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetSavedCommentDataByFileName(action) {
  try {
    // yield put(setPageTopLoader(true));
    const env = process.env.REACT_APP_ENV;
    const email = yield call(getLocalStorageItem, 'email');
    const fileName = action.payload;
    const table = env === 'prod' ? "PicTreeViewerCommentProd" : "PicTreeViewerCommentDev";
    const sqlQueryGetTag = {
      query: `
        SELECT * FROM ${table} WHERE user_email = '${email}' AND file_name = '${fileName}';
      `,
      type: "select"
    };
    const [getCommentDataTemp] =
      yield all([
        call(
          getDataFromKpiDash,
          sqlQueryGetTag,
          ENDPOINT.GET_DATA_KPIDASH_API
        ),
      ]);
    if (getCommentDataTemp.length > 0) {
      yield put(setAssetComment(getCommentDataTemp[0][15]["stringValue"])); // Fill up
    }
    // yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(APP.GET_SEASON_DATA, handleSeasonData);
  yield takeEvery(APP.GET_MISSION_DATA, handleMissionData);
  yield takeEvery(APP.SET_SAVED_SELECTED_TAG_DATA, handleSetSavedTagData);
  yield takeEvery(APP.GET_SAVED_SELECTED_TAG_DATA_BY_FILENAME, handleGetSavedTagDataByFileName);
  yield takeEvery(APP.SET_SAVED_COMMENT_DATA, handleSetSavedCommentData);
  yield takeEvery(APP.GET_SAVED_COMMENT_DATA_BY_FILENAME, handleGetSavedCommentDataByFileName);
}
