import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Drawer, Divider } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardWrapper from "../containers/DashboardWrapper";
import {
  setCurrentPage,
  getImageDataByFileName,
  getSavedSelectedTagDataByFileName,
  setPeriodType,
  setDrawerTagTip,
  getSavedCommentDataByFileName,
  getImageDataByReportId,
} from "../actions";
import ReportMeta from "../components/Viewer/ReportMeta";
import ReportMagnify from "../components/Viewer/ReportMagnify";
import TagConfig from "../config/TagConfig";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    marginTop: theme.spacing(0),
  },
  imageMeta: {
    marginTop: 0,
    marginBottom: 8,
    maxWidth: 1200,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 62,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  tagTextWrapper: {
    padding: "16px",
  },
  tagTip: {
    fontWeight: "bold",
    marginBottom: 16,
  },
  tagText: {
    fontSize: "14px",
    marginBottom: 16,
  },
}));

const ReportViewer = ({
  setCurrentPage,
  getImageDataByFileName,
  filterDrawerState,
  viewer,
  viewerImageData,
  getSavedSelectedTagDataByFileName,
  getSavedCommentDataByFileName,
  setPeriodType,
  setDrawerTagTip,
  periodType,
  openTagTip,
  getImageDataByReportId,
  reportImageData,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [tagData, setTagData] = useState([]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);

    // URLパスからReportIDを取得
    const pathParts = window.location.pathname.split("/");
    const encodedFileName = pathParts[pathParts.length - 1];
    const reportId = decodeURIComponent(encodedFileName);
    if (reportId) {
      getImageDataByReportId(reportId);
    }
  }, []);

  useEffect(() => {
    if (viewerImageData.length > 0 && viewerImageData[0] !== undefined) {
      if (viewerImageData[0]["game_space_type"] === "SEASON") {
        setTagData(TagConfig.seasonTag);
        setPeriodType("season");
      } else {
        setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
        setPeriodType("mission");
      }
    }
  }, [viewerImageData]);

  return (
    <DashboardWrapper>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.contentGrid}
          >
            <Grid item xs={12} md={12} lg={10} className={classes.imageMeta}>
              <ReportMeta viewer={reportImageData} />
            </Grid>
            <Grid item xs={12} md={12} lg={10}>
              {reportImageData && reportImageData.length > 0 ? (
                <ReportMagnify viewer={reportImageData} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={openTagTip}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => setDrawerTagTip(false)}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.tagTextWrapper}>
          {tagData.length > 0 && (
            <>
              <div className={classes.tagTip}>
                {periodType === "season" ? "シーズン" : "ミッション"}
                結果タグ説明
              </div>
              {tagData.length > 0 &&
                tagData.map((row, index) => (
                  <div className={classes.tagText} key={index}>
                    {row["tip"]}
                  </div>
                ))}
            </>
          )}
        </div>
      </Drawer>
    </DashboardWrapper>
  );
};

ReportViewer.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  filterDrawerState: state.app.filterDrawerState,
  viewer: state.viewer.imageDataByFilename,
  viewerImageData: state.viewer.viewerImageData,
  periodType: state.app.periodType,
  openTagTip: state.app.openTagTip,
  reportImageData: state.report.reportImageData,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getImageDataByFileName: (param) => dispatch(getImageDataByFileName(param)),
  getSavedSelectedTagDataByFileName: (param) => dispatch(getSavedSelectedTagDataByFileName(param)),
  getSavedCommentDataByFileName: (param) => dispatch(getSavedCommentDataByFileName(param)),
  setPeriodType: (param) => dispatch(setPeriodType(param)),
  setDrawerTagTip: (param) => dispatch(setDrawerTagTip(param)),
  getImageDataByReportId: (param) => dispatch(getImageDataByReportId(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewer);
