import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Chip } from "@material-ui/core";
import * as Var from "../../styles/variables";
import { setPeriodType } from "../../actions";
import * as H from "../../utils/helper";
import TepcoConfig from "../../config/TepcoConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontSize: 12,
      color: "#484848",
    },
    filterTitle: {
      fontSize: 14,
      color: "#484848",
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      color: Var.btnLabelColor,
    },
    filterItem: {
      marginTop: theme.spacing(1),
    },
  })
);

const getAssetTypeName = (assetType) => {
  switch(assetType) {
    case "POWER_POLE":
      return "電柱（全て）";
    case "POWER_POLE_HAS_PROTECTIVE_TUBE_ALL":
      return "電柱（防護管付き・全て電柱（防護管付き・全て）";
    case "POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC":
      return "電柱（防護管付き・写真あり）";
    case "MAN_HOLE":
      return "マンホール";
    case "HAND_HOLE":
      return "ハンドホール";
    case "GRATING":
      return "換気口";
    case "GROUND_EQUIPMENT-MS":
      return "地上設備 MS";
    case "GROUND_EQUIPMENT-PM":
      return "地上設備 PM";
    default:
      break;
  }
}

const FilterApplied = ({
  setPeriodType,
  filteredTotal,
  filteredTotalReport,
  filterStore,
  reportFilterStore,
  filterType,
}) => {
  const classes = useStyles();
  const [assetTypeSel, setAssetTypeSel] = useState("全て");
  const signNumberValue = filterType === "report"
    ? reportFilterStore.signNumber === null ? "全て" : reportFilterStore.signNumber
    : filterStore.signNumber === null ? "全て" : filterStore.signNumber;
  const assetValue = filterType === "report"
    ? reportFilterStore.assetId === null ? "全て" : reportFilterStore.assetId
    : filterStore.assetId === null ? "全て" : filterStore.assetId;
  const assetTypeValue = filterStore.assetType === "all" && filterStore.assetType === "MISSION_ASSET"
    ? "全て" : getAssetTypeName(filterStore.assetType);
  const [manageGroupSel, setManageGroupSel] = useState("全て");
  const sortPrefix = filterType === "photo" ? "撮影日時" : "報告日時";
  const sortValue = filterType === "report"
    ? reportFilterStore.order === "desc"
      ? `${sortPrefix} - 降順`
      : `${sortPrefix}  - 昇順`
    : filterStore.type === "occurred_datetime" && filterStore.order === "desc"
      ? `${sortPrefix} - 降順`
      : `${sortPrefix}  - 昇順`;
  const filterTotalNumber = filterType === "report" ? filteredTotalReport : filteredTotal;

  useEffect(() => {
    const asv = filterStore.assetType === "all" || filterStore.assetType === "MISSION_ASSET"
    ? "全て" : getAssetTypeName(filterStore.assetType);
    setAssetTypeSel(asv);
  }, [filterStore.assetType]);

  useEffect(() => {
    const asv = reportFilterStore.assetType === "all" || reportFilterStore.assetType === "MISSION_ASSET"
    ? "全て" : getAssetTypeName(reportFilterStore.assetType);
    setAssetTypeSel(asv);
  }, [reportFilterStore.assetType]);

  useEffect(() => {
    const manageGroupValue = filterStore.manageGroup.key === "all"
    ? "全て" : TepcoConfig.manageGroupLabel[filterStore.manageGroup.key];
    setManageGroupSel(manageGroupValue);
  }, [filterStore.manageGroup]);

  useEffect(() => {
    const manageGroupValue = reportFilterStore.manageGroup.key === "all"
    ? "全て" : TepcoConfig.manageGroupLabel[reportFilterStore.manageGroup.key];
    setManageGroupSel(manageGroupValue);
  }, [reportFilterStore.manageGroup]);

  return (
    <>
      <div className={classes.title}>適用絞り込み条件</div>
      <Grid
        container
        direction="column"
        justifyContent={"space-between"}
        className={classes.filtered}
      >
        <Grid item className={classes.filterItem}>
          <span className={classes.filterTitle}>標識番号</span>： {signNumberValue}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
          <span className={classes.filterTitle}>設備番号</span>： {assetValue}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
          <span className={classes.filterTitle}>アセットタイプ</span>： {assetTypeSel}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
          <span className={classes.filterTitle}>配電保守グループ</span>： {manageGroupSel}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
          <span className={classes.filterTitle}>ソート</span>： {sortValue}
        </Grid>
        {filterType === "report" &&
          <Grid item className={classes.filterItem}>
            <span className={classes.filterTitle}>報告タグ</span>：
            {reportFilterStore.tag.length > 0 ? (
              reportFilterStore.tag.map((option, index) => (
                <Chip
                  key={index}
                  size="small"
                  label={option}
                  style={{
                    backgroundColor: '#f3ee91',
                    color: 'black',
                    paddingTop: 0,
                    marginRight: 5,
                  }}
                />
              ))
            ) : (
              "全て"
            )}
          </Grid>
        }
        <Grid item className={classes.filterItem}>
          <span className={classes.filterTitle}>件数</span>：
          {H.FormatNumber.formatCommaThousand(filterTotalNumber)}
        </Grid>
      </Grid>
    </>
  );
};

FilterApplied.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  filteredTotal: state.home.filteredTotal,
  filteredTotalReport: state.report.filteredTotal,
  filterStore: state.home.filter,
  reportFilterStore: state.report.filter,
  filterType: state.app.filterType,
});

const mapDispatchToProps = (dispatch) => ({
  setPeriodType: (param) => dispatch(setPeriodType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(FilterApplied));
