import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { APP, HOME, ENDPOINT } from '../constants';

import {
  setPageTopLoader,
  setHomeTotalStatsData,
  setHomeImageData,
  setFilteredTotal,
} from '../actions';

import {
  getJwtToken,
  getDataFromSxi,
  getDataFromKpiDash,
} from '../api';

const getLocalStorageItem = (key) => {
    return localStorage.getItem(key);
};

const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

const keywords = ["ぬまづの宝", "防災"];

export const periodType = state => state.app.periodType;
export const selectedSeason = state => state.app.selectedSeason;
export const selectedMission = state => state.app.selectedMission;
export const selectedMissionLabel = state => state.app.selectedMissionLabel;
export const startDate = state => state.app.dateRange[0];
export const endDate = state => state.app.dateRange[1];
export const filter = state => state.home.filter;

export function* handleHomeTotalStatsData() {
  try {
      const jwtToken = yield call(getJwtToken);
      // console.log("LOG jwtToken:", jwtToken);
      let totalAllTimeStats = {};
      let loading = 'loading';
      totalAllTimeStats.denchu = loading;
      totalAllTimeStats.hh = loading;
      totalAllTimeStats.mh = loading;
      yield put(setHomeTotalStatsData(totalAllTimeStats));

      let dateFrom = yield select(startDate);
      dateFrom = moment(dateFrom).tz("Asia/Tokyo").format('YYYY-MM-DD');
      let dateTo = yield select(endDate);
      dateTo = moment(dateTo).tz("Asia/Tokyo").format('YYYY-MM-DD');

      const sqlQueryGetTotalCountDenchu = {"query": `SELECT COUNT(*) as count FROM photo_of_power_pole`}
      // const sqlQueryGetTotalCountHH = {"query": `SELECT COUNT(*) as count FROM photo_of_man_hole`}
      // const sqlQueryGetTotalCountMH = {"query": `SELECT COUNT(*) as count FROM photo_of_hand_hole`}
      const [dataTotalDenchuTemp, /*dataTotalHHTemp, dataTotalMHTemp*/] = yield all([
        call(getDataFromKpiDash, sqlQueryGetTotalCountDenchu, ENDPOINT.GET_DATA_API),
        // call(getDataFromKpiDash, sqlQueryGetTotalCountHH, ENDPOINT.GET_DATA_API),
        // call(getDataFromKpiDash, sqlQueryGetTotalCountMH, ENDPOINT.GET_DATA_API),
      ]);

      const dataTotalDenchu = JSON.parse(dataTotalDenchuTemp.body);
      // const dataTotalHH = JSON.parse(dataTotalHHTemp.body);
      // const dataTotalMH = JSON.parse(dataTotalMHTemp.body);
      totalAllTimeStats = {
          denchu: dataTotalDenchu[0]["count"] !== undefined ? dataTotalDenchu[0]["count"] : "--",
          hh: "--", //dataTotalHH[0]["count"] !== undefined ? dataTotalHH[0]["count"] : "--",
          mh: "--", //dataTotalMH[0]["count"] !== undefined ? dataTotalMH[0]["count"] : "--",
      }
      yield put(setHomeTotalStatsData(totalAllTimeStats));
  } catch (error) {
      console.log(error);
  }
}

export function* handleHomeImageData(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    yield put(setPageTopLoader(true));
    yield put(setFilteredTotal("--"));
    const env = process.env.REACT_APP_ENV;
    const type = yield select(periodType);
    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    const assetFilter = yield select(filter);
    const seasonIdSelected = yield select(selectedSeason);
    const missioIdSelected = yield select(selectedMission);
    const missionSelectedName = yield select(selectedMissionLabel);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format('YYYY-MM-DD');
    dateTo = moment(dateTo).tz("Asia/Tokyo").format('YYYY-MM-DD');
    const pageNumber = action.payload.pageNumber === undefined ? 1 : action.payload.pageNumber || 1;
    const itemsPerPage = 10;
    const offset = (pageNumber - 1) * itemsPerPage;
    const orderValue = assetFilter.order == "desc" ? "DESC" : "ASC";
    const equipmentNumber = assetFilter.assetId === null ? false : assetFilter.assetId;
    const signNumber = assetFilter.signNumber === null ? false : assetFilter.signNumber;
    let assetType = assetFilter.assetType === "all" ? false : assetFilter.assetType;
    const manageGroup = assetFilter.manageGroup.key === "all" ? false : assetFilter.manageGroup.value;

    // 地上設備 x 2種類
    let assetType2 = "";
    if (assetType && assetType.includes("GROUND_EQUIPMENT")) {
      assetType2 = assetType.split("-")[1];
      assetType = assetType.split("-")[0];
    }

    const seasonIdQuery = type === "season" && seasonIdSelected !== "all"
      ? `AND ppa.game_space_id = '${seasonIdSelected}'` : "";

    const missionIdQUery = type === "mission" && missioIdSelected !== "blank" && missioIdSelected !== "all"
      ? `AND pae.game_space_id = '${missioIdSelected}'` : "";

    const assetIdQuery = equipmentNumber
      ? `AND padtep.equipment_number = '${equipmentNumber}'` : "";

    const signNumberQuery = signNumber
      ? `AND padtep.sign_number = '${signNumber}'` : "";

    let assetTypeQuery = assetType && assetType !== "POWER_POLE_HAS_PROTECTIVE_TUBE_ALL"
      ? `AND pa.type = '${assetType}'` : "";

    const assetTypeQuery2 = assetType2 !== "" ? `AND padtep.equipment_code = '${assetType2}'` : "";

    // 防護管付き電柱・全て & 写真あり
    let bogokanJoinQuery = ``;
    let bogokanWhereQuery = ``;
    if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_ALL")) {
      bogokanJoinQuery = `JOIN power_pole pp ON ppa.power_asset_id = pp.id`
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE'`
    } else if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC")) {
      bogokanJoinQuery = `
        JOIN power_asset_angle_of_view_name_v2 paaovn ON paaovn.power_asset_photography_target_id = ppa.power_asset_photography_target_id
        AND paaovn.language_code = 'JA'
        JOIN power_pole pp ON ppa.power_asset_id = pp.id
      `;
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE' AND paaovn.angle_of_view_name like '% - 防護管%'`
      assetTypeQuery = "";
    }

    // 配電保守グループ
    let managementGroupQuery = ``;
    if (manageGroup) {
      const manageGroupCommaSeparated = manageGroup.join("','");
      managementGroupQuery = `AND padtep.place_code IN ('${manageGroupCommaSeparated}')`;
    }

    const dateRagenQuery = type === "season"
      ? `AND CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') >= '${dateFrom} 00:00:00'
         AND CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') <= '${dateTo} 23:59:59'`
      : "";

    const assetDetailQuery = type === "mission" && keywords.some(keyword => missionSelectedName.includes(keyword))
      ? `power_asset_detail_of_mission_venue padtep ON pae.power_asset_id = padtep.power_asset_id`
      : `power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id`;

    let sqlQuery = {}
    sqlQuery.query = `
      SELECT
          ppa.*,
          gs.*,
          gt.*,
          pae.occurred_datetime AS occurred_datetime,
          CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
          ps.*,
          padtep.*,
          rrpb.review_request_id
      FROM
          power_asset_event pae
      JOIN
          photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
          AND ppa.player_id = pae.player_id
          AND ppa.game_space_id = pae.game_space_id
      JOIN
          game_space gs ON gs.id = pae.game_space_id
      JOIN
          game_term gt ON gt.id = gs.game_term_id
      JOIN
          player_state ps ON pae.player_id = ps.player_id
      JOIN
          ${assetDetailQuery}
      JOIN
          review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
      JOIN
        	power_asset pa ON pa.id = pae.power_asset_id
      ${bogokanJoinQuery}
      WHERE
          pae.type = 'PHOTOGRAPH'
          ${dateRagenQuery}
          ${seasonIdQuery}
          ${missionIdQUery}
          ${assetIdQuery}
          ${signNumberQuery}
          ${assetTypeQuery}
          ${assetTypeQuery2}
          ${bogokanWhereQuery}
          ${managementGroupQuery}
      GROUP BY
          pae.power_asset_id, ps.player_id, occurred_datetime
      ORDER BY
          pae.occurred_datetime ${orderValue}
      LIMIT ${itemsPerPage}
      OFFSET ${offset};
    `;
    // AND gt.type = '${type.toUpperCase()}' removed
    // console.log("sqlQuery:", sqlQuery.query);

    // COUNT
    let sqlQueryCount = {
      query: `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT
                pae.power_asset_id,
                ps.player_id,
                pae.occurred_datetime
            FROM
                power_asset_event pae
            JOIN
                photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
                AND ppa.player_id = pae.player_id
                AND ppa.game_space_id = pae.game_space_id
            JOIN
                game_space gs ON gs.id = pae.game_space_id
            JOIN
                game_term gt ON gt.id = gs.game_term_id
            JOIN
                player_state ps ON pae.player_id = ps.player_id
            JOIN
                ${assetDetailQuery}
            JOIN
                review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
            JOIN
                power_asset pa ON pa.id = pae.power_asset_id
            ${bogokanJoinQuery}
            WHERE
                pae.type = 'PHOTOGRAPH'
                ${dateRagenQuery}
                ${seasonIdQuery}
                ${missionIdQUery}
                ${assetIdQuery}
                ${signNumberQuery}
                ${assetTypeQuery}
                ${assetTypeQuery2}
                ${bogokanWhereQuery}
                ${managementGroupQuery}
            GROUP BY
                pae.power_asset_id, ps.player_id, occurred_datetime
        ) AS subquery;
      `
    };

    const [imageFileDataTemp, imageFileDataCountTemp] = yield all([
      call(getDataFromKpiDash, sqlQuery, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryCount, ENDPOINT.GET_DATA_API),
    ]);

    const top10FileData = JSON.parse(imageFileDataTemp.body);
    const countFileData = JSON.parse(imageFileDataCountTemp.body);
    // console.log("LOG top10FileData:", top10FileData);
    // console.log("LOG countFileData:", countFileData[0]["total_count"]);

    let photoPathArray = [], photoFileNameArray = [];
    _.each(top10FileData, function(photoData) {
      photoPathArray.push(photoData.object_key);
      photoFileNameArray.push(photoData.file_name);
    });

    // Deprecated: S3 bucket access is set public @2024/12/23
    // const signedUrlCalls = photoPathArray.map((path, index) => {
    //   return call(getDataFromSxi, {
    //     filePath: path,
    //     fileName: photoFileNameArray[index]
    //   }, ENDPOINT.GET_DATA_SXI_PHOTO_BY_FILE, jwtToken);
    // });
    // const signedUrlResponses = yield all(signedUrlCalls);

    // // Step 3: Parse and store signed URLs
    // const recent10PhotoSignedUrls = signedUrlResponses.map(response => {
    //   return JSON.parse(response.body).signedUrl; // Assuming `signedUrl` is the field with the URL
    // });

    // Step 4: Get tags by fileName
    const email = yield call(getLocalStorageItem, 'email');
    const table = env === 'prod' ? "PicTreeViewerTagProd" : "PicTreeViewerTagDev";
    const savedTagCalls = photoFileNameArray.map((fileName, index) => {
      const sqlQueryGetTag = {
        query: `
          SELECT file_name, tag_array, tag_flat FROM ${table} WHERE user_email = '${email}' AND file_name = '${fileName}';
        `,
        type: "select"
      };
      return call(getDataFromKpiDash, sqlQueryGetTag, ENDPOINT.GET_DATA_KPIDASH_API);
    });
    const savedTagByFileNameResponses = yield all(savedTagCalls);
    // console.log("LOG savedTagByFileNameResponses:", savedTagByFileNameResponses);

    const photoAllData = top10FileData.map((photoData, index) => ({
      signNumber: photoData.sign_number,
      signName: photoData.sign_name !== null && photoData.sign_name !== undefined ? photoData.sign_name : "--",
      name: photoData.equipment_number,
      placeCode: photoData.place_code !== null ? photoData.place_code : "--",
      tag: savedTagByFileNameResponses[index] && savedTagByFileNameResponses[index]?.length > 0
        ? JSON.parse(savedTagByFileNameResponses[index][0][1]["stringValue"])
        : [],
      user: photoData.name,
      fileUrl: `${S3_BUCKET_BASE_URL}/${photoPathArray[index]}`, //recent10PhotoSignedUrls[index],
      fileName: photoData.file_name.split(".jpg")[0],
      occurred_datetime: photoData.occurred_datetime_jst
    }));
    // console.log("LOG photoData:", photoAllData);
    yield put(setFilteredTotal(countFileData[0]["total_count"]));
    yield put(setHomeImageData(photoAllData.length === 0 ? [] : photoAllData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleGetSavedTagDataByEmail() {
    try {
      yield put(setPageTopLoader(true));
      const env = process.env.REACT_APP_ENV;
      const jwtToken = yield call(getJwtToken);
      const email = yield call(getLocalStorageItem, 'email');
      const assetFilter = yield select(filter);
      const tagWhereClause = assetFilter.tag.map(tag => `tag_flat LIKE '%${tag}%'`).join(' OR ');
      const table = env === 'prod' ? "PicTreeViewerTagProd" : "PicTreeViewerTagDev";
      const sqlQueryGetTag = {
        query: `
          SELECT * FROM ${table} WHERE user_email = '${email}' AND (${tagWhereClause});
        `,
        type: "select"
      };
      const [getTagDataTemp] =
        yield all([
          call(
            getDataFromKpiDash,
            sqlQueryGetTag,
            ENDPOINT.GET_DATA_KPIDASH_API
          ),
        ]);
      // console.log("LOG getTagDataTemp:", getTagDataTemp);
      if (getTagDataTemp.length > 0) {
        let photoPathArray = [], photoFileNameArray = [];
        _.each(getTagDataTemp, function(photoData) {
          photoPathArray.push(photoData[4]["stringValue"]);
          photoFileNameArray.push(photoData[3]["stringValue"]);
        });

        const signedUrlCalls = photoPathArray.map((path, index) => {
          return call(getDataFromSxi, {
            filePath: path,
            fileName: photoFileNameArray[index]
          }, ENDPOINT.GET_DATA_SXI_PHOTO_BY_FILE, jwtToken);
        });
        const signedUrlResponses = yield all(signedUrlCalls);
        const recent10PhotoSignedUrls = signedUrlResponses.map(response => {
          return JSON.parse(response.body).signedUrl;
        });
        // console.log("LOG recent10PhotoSignedUrls:", recent10PhotoSignedUrls);

        const photoAllData = getTagDataTemp.map((photoData, index) => ({
          signNumber: photoData[11]["stringValue"],
          name: photoData[12]["stringValue"],
          tag: JSON.parse(photoData[15]["stringValue"]),
          user: photoData[14]["stringValue"],
          fileUrl: recent10PhotoSignedUrls[index],
          fileName: photoData[3]["stringValue"].split(".jpg")[0],
          occurred_datetime: photoData[5]["stringValue"]
        }));
        yield put(setFilteredTotal(getTagDataTemp.length));
        yield put(setHomeImageData(photoAllData));
      } else {
        yield put(setFilteredTotal(0));
        yield put(setHomeImageData([]));
      }
      yield put(setPageTopLoader(false));
    } catch (error) {
      console.log(error);
    }
  }

export default function* watchMapSettings() {
  // yield takeEvery(HOME.GET_TOTAL_STATS_DATA, handleHomeTotalStatsData);
  yield takeEvery(HOME.GET_HOME_IMAGE_DATA, handleHomeImageData);
  yield takeEvery(APP.GET_SAVED_SELECTED_TAG_DATA_BY_EMAIL, handleGetSavedTagDataByEmail);
}