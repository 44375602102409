import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  IconButton,
  Chip,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelpIcon from "@material-ui/icons/Help";
import * as Var from "../../styles/variables";
import { setSelectedTag, setSavedSelectedTagData, setDrawerTagTip, setAssetComment, setSavedCommentData } from "../../actions";
import moment from "moment-timezone";
import TagConfig from "../../config/TagConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      // flex: 1,
      // position: 'relative',
    },
    title: {
      fontSize: 12,
      color: "#484848",
    },
    item: {
      marginTop: theme.spacing(1),
    },
    filtered: {
      flex: 1,
      maxWidth: 1200,
      position: "relative",
    },
    iconTooltip: {
      fontSize: 18,
      marginTop: -4,
      marginLeft: 4,
    },
    tagSelect: {
      marginTop: theme.spacing(1),
      fontSize: 16,
      minWidth: 600,
      maxWidth: 1000,
      "& .MuiInputLabel-root": {
        fontSize: "16px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "16px", // Customize input text size (if needed)
      }
    },
    tagWrapper: {
      display: "flex",
    },
    tagDropdown: {
      marginLeft: -32,
    },
    saveButton: {
      fontWeight: "medium",
      height: 30,
      marginTop: 12,
      marginLeft: 16,
      paddingTop: 8,
    },
    formWrapper: {
      display: "flex",
    },
    form: {
      marginRight: 16,
      width: "85.9%",
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    }
  })
);

const ImageMeta = ({
  periodType,
  viewer,
  setSelectedTag,
  selectedTag,
  setSavedSelectedTagData,
  tagSavingLoader,
  commentSavingLoader,
  setDrawerTagTip,
  openTagTip,
  setAssetComment,
  setSavedCommentData,
  assetComment,
}) => {
  const classes = useStyles();
  const [tagData, setTagData] = useState([]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
  }, [periodType]);

  const handleSelectTagChange = (tags) => {
    const savingTagData = {
      tags: tags,
      fileName: viewer[0].file_name,
      object_key: viewer[0].object_key,
      occurred_datetime: viewer[0].occurred_datetime,
      game_space_id: viewer[0].game_space_id,
      game_space_type: viewer[0].game_space_type,
      review_request_id: viewer[0].review_request_id,
      photo_of_power_asset_id: viewer[0].photo_of_power_asset_id,
      place_code: viewer[0].place_code,
      sign_number: viewer[0].sign_number,
      equipment_number: viewer[0].equipment_number,
      player_id: viewer[0].player_id,
      player_name: viewer[0].name,
    }
    console.log("LOG savingTagData:", savingTagData);
    setSelectedTag(savingTagData);
  };

  const handleCommentChange = (event) => {
    console.log("event:", event.target.value);
    const savingCommentData = {
      fileName: viewer[0].file_name,
      object_key: viewer[0].object_key,
      occurred_datetime: viewer[0].occurred_datetime,
      game_space_id: viewer[0].game_space_id,
      game_space_type: viewer[0].game_space_type,
      review_request_id: viewer[0].review_request_id,
      photo_of_power_asset_id: viewer[0].photo_of_power_asset_id,
      place_code: viewer[0].place_code,
      sign_number: viewer[0].sign_number,
      equipment_number: viewer[0].equipment_number,
      player_id: viewer[0].player_id,
      player_name: viewer[0].name,
      comment: event.target.value,
    }
    setAssetComment(savingCommentData);
  };

  return (
    <div className={classes.container}>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        className={classes.filtered}
      >
        {viewer.length > 0 && (
          <>
            <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>標識番号 | 標識名：</Typography>
              {viewer[0]["sign_number"] !== undefined
                ? `${viewer[0]["sign_number"]} | ${viewer[0]["sign_name"]}`
                : "-"}
            </Grid>
            <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>設備番号：</Typography>
              {viewer[0]["equipment_number"] !== undefined
                ? `${viewer[0]["equipment_number"]}`
                : "-"}
            </Grid>
            <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>撮影点数：</Typography>
              {viewer.length}
            </Grid>
            <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>報告者：</Typography>
              {viewer[0].name}
            </Grid>
            {/* <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>運用：</Typography>
              {viewer[0].game_space_type === "SEASON"
              ? "シーズン"
              : "ミッション"}
            </Grid> */}
            <Grid item className={classes.item} xs={4} md={2}>
              <Typography color="textSecondary" style={{ marginLeft: 0 }}>撮影日時：</Typography>
              {viewer[0].occurred_datetime_jst
                ? viewer[0].occurred_datetime_jst
                : "-"}
            </Grid>
            <Grid item className={classes.item} xs={12}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                className={classes.filtered}
              >
                <Grid item className={classes.item} xs={12} md={6} lg={2}>
                  <div style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 14 }}>結果入力
                    <IconButton
                      className={classes.iconTooltip}
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => setDrawerTagTip(!openTagTip)}
                      disableRipple={true}
                      disableFocusRipple={true}
                    >
                      <HelpIcon
                        fontSize="small"
                      />
                    </IconButton>
                  ：
                  </div>
                </Grid>
                <Grid item className={classes.tagDropdown} xs={12} md={12} lg={8}>
                {tagData !== null && tagData.length > 0 && (
                  <div className={classes.tagWrapper}>
                  <Autocomplete
                    multiple
                    id="tag-select-autocomplete"
                    options={tagData}
                    getOptionLabel={(option) => option.value || ""}
                    getOptionSelected={(option, value) => option.value.toString() === value.toString()}
                    value={selectedTag.tags !== undefined &&
                          selectedTag.tags.length > 0
                            ? tagData.filter(tag => selectedTag.tags.includes(tag.value.toString()))
                            : []}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const selectedValues = newValue.map((tag) => tag.value.toString());
                        handleSelectTagChange(selectedValues);
                      } else {
                        handleSelectTagChange([]);
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          size="small"
                          label={option.value}
                          {...getTagProps({ index })}
                          style={{ backgroundColor: '#ffed6f', color: 'black' }} // Set Chip color
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder={selectedTag.tags !== undefined && selectedTag.tags.length < 1 ? "選択する" : ""}
                        className={classes.tagSelect}
                      />
                    )}
                    renderOption={(option) => (
                      <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                        <Typography variant="body1">
                          {option.tip}
                        </Typography>
                      </div>
                    )}
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    color="default"
                    className={classes.saveButton}
                    onClick={setSavedSelectedTagData}
                  >
                    {tagSavingLoader ? "保存中.." : "保存"}
                  </Button>
                  </div>
                )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.item} xs={12}>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                className={classes.filtered}
              >
                <Grid item className={classes.item} xs={12} md={8} lg={9}>
                  <div className={classes.formWrapper}>
                    <form className={classes.form} noValidate autoComplete="off">
                      <TextField
                        id="outlined-multiline-static"
                        multiline
                        defaultValue={assetComment !== "" ? assetComment : ""}
                        variant="outlined"
                        placeholder="コメント入力 (最大400字)"
                        fullWidth
                        inputProps={{ style: { fontSize: 13, marginTop: -8, padding: '2px 4px'  } }}
                        onChange={handleCommentChange}
                      />
                    </form>
                    <Button
                      variant="outlined"
                      size="small"
                      color="default"
                      className={classes.saveButton}
                      style={{ height: '100%', marginLeft: 8 }}
                      onClick={setSavedCommentData}
                    >
                      {commentSavingLoader ? "保存中.." : "保存"}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

ImageMeta.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  periodType: state.app.periodType,
  selectedTag: state.app.selectedTag,
  tagSavingLoader: state.app.tagSavingLoader,
  openTagTip: state.app.openTagTip,
  commentSavingLoader: state.app.commentSavingLoader,
  assetComment: state.app.assetComment,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTag: (param) => dispatch(setSelectedTag(param)),
  setSavedSelectedTagData: (param) => dispatch(setSavedSelectedTagData(param)),
  setDrawerTagTip: (param) => dispatch(setDrawerTagTip(param)),
  setAssetComment: (param) => dispatch(setAssetComment(param)),
  setSavedCommentData: (param) => dispatch(setSavedCommentData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ImageMeta));
