import { put, call, all, takeEvery, select } from "redux-saga/effects";
import _ from "lodash";
import { IMAGE, ENDPOINT } from "../constants";

import { setPageTopLoader, setAllImageDataByFileName } from "../actions";

import { getDataFromKpiDash } from "../api";

const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

export const selectAllImageData = (state) => state.image.allImageDataByFilename;

export function buildQuery1(fileName) {
  return {
    query: `
      SELECT
          ppa.*,
          gs.*,
          gt.*,
          pae.occurred_datetime AS occurred_datetime,
          CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
          ps.*,
          padtep.*,
          rrpb.review_request_id
      FROM
          power_asset_event pae
      JOIN
          photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
          AND ppa.player_id = pae.player_id
          AND ppa.game_space_id = pae.game_space_id
      JOIN
          game_space gs ON gs.id = pae.game_space_id
      JOIN
          game_term gt ON gt.id = gs.game_term_id
      JOIN
          player_state ps ON pae.player_id = ps.player_id
      JOIN
          power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
      JOIN review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
      WHERE
          pae.type = 'PHOTOGRAPH'
          AND ppa.file_name = '${fileName}'
      GROUP BY
          pae.power_asset_id, ps.player_id, occurred_datetime
      ORDER BY
          pae.occurred_datetime DESC
      LIMIT 1
    `
  };
}

export function buildQuery2(reviewRequestId) {
  return {
    query: `
      SELECT
        *,
        DATE_FORMAT(CONVERT_TZ(rre.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d %H:%i:%s') AS occurred_datetime_jst
      FROM review_request_photo_bind rrpb
      JOIN photo_of_power_asset ppa ON rrpb.photo_of_power_asset_id = ppa.id
      JOIN game_space gs ON gs.id = ppa.game_space_id
      JOIN game_term gt ON gt.id = gs.game_term_id
      JOIN power_asset_detail_of_tepco padtep ON ppa.power_asset_id = padtep.power_asset_id
      JOIN player_state ps ON ps.player_id = ppa.player_id
      JOIN review_request_event rre ON rrpb.review_request_id = rre.review_request_id
      WHERE rrpb.review_request_id = '${reviewRequestId}'
      AND rre.type = 'REGISTERED';
    `
  };
}

export function* handleAllImageDataByFileName(action) {
  try {
    yield put(setPageTopLoader(true));
    const fileNames = action.payload; // 配列で受け取る
    let allImageUrls = [];

    // 各ファイル名に対して処理
    for (const url of fileNames) {
      // クエリ1で review_request_id を取得
      const fileName = url.split("/").pop();
      const sqlQuery1 = buildQuery1(fileName);
      const reviewRequestDataTemp = yield call(getDataFromKpiDash, sqlQuery1, ENDPOINT.GET_DATA_API);
      const reviewRequestData = JSON.parse(reviewRequestDataTemp.body || "[]");
      if (!reviewRequestData.length) {
        console.warn(`No review_request_id found for file: ${fileName}`);
        continue;
      }
      const reviewRequestId = reviewRequestData[0].review_request_id;

      // クエリ2で関連する画像データを取得
      const sqlQuery2 = buildQuery2(reviewRequestId);
      const imageFilesDataTemp = yield call(getDataFromKpiDash, sqlQuery2, ENDPOINT.GET_DATA_API);
      const imageFilesData = JSON.parse(imageFilesDataTemp.body);

      // URL のみを抽出
      const imageUrls = imageFilesData.map(
        (photoData) => `${S3_BUCKET_BASE_URL}/${photoData.object_key}`
      );

      allImageUrls = [...allImageUrls, ...imageUrls];
    }

    //重複を排除して一括で Redux に更新
    const uniqueData = Array.from(new Set(allImageUrls));
    yield put(setAllImageDataByFileName(uniqueData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.error(error);
    yield put(setPageTopLoader(false));
    yield put({ type: IMAGE.ERROR, payload: error.message });
  }
}
export default function* watchMapSettings() {
  yield takeEvery(IMAGE.GET_ALL_IMAGE_DATA_BY_FILENAME, handleAllImageDataByFileName);
}
