import { combineReducers } from 'redux';
import appReducer from './appReducer';
import homeReducer from './homeReducer';
import viewerReducer from './viewerReducer';
import imageReducer from './imageReducer';
import reportReducer from './reportReducer';

const rootReducer = combineReducers({
    app: appReducer,
    home: homeReducer,
    viewer: viewerReducer,
    image: imageReducer,
    report: reportReducer,
});

export default rootReducer;
