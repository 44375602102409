import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Var from "../../styles/variables";
import moment from "moment-timezone";
import {
  setDateRange,
  setPeriodType,
  setSelectedSeason,
  setSelectedSeasonLabel,
  getSeasonData,
  getMissionData,
  setSelectedMission,
  setSelectedMissionLabel,
  setFilter,
} from "../../actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(1.2),
      marginLeft: theme.spacing(1),
      height: theme.spacing(4),
      paddingTop: theme.spacing(1.5),
      color: Var.btnLabelColor,
    },
    formControl: {
      marginTop: theme.spacing(.4),
      marginRight: theme.spacing(0),
    },
    label: {
      fontSize: 18,
      marginLeft: theme.spacing(-1),
    },
    seelctControl: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(-3),
    },
    missionSelect: {
      marginTop: theme.spacing(-1.5),
      minWidth: 450,
    },
    missionUnSelect: {
      marginTop: theme.spacing(-1.5),
      minWidth: 450,
    },
    missionAllSelect: {
      marginTop: theme.spacing(0),
      width: 450,
    },
    periodLabel: {
      marginTop: theme.spacing(1.5),
      marginRight: theme.spacing(3),
    }
  })
);

const Period = ({
  setDateRange,
  periodType,
  selectedSeason,
  setPeriodType,
  setSelectedSeason,
  setSelectedSeasonLabel,
  getSeasonData,
  getMissionData,
  seasonData,
  missionData,
  setSelectedMission,
  setSelectedMissionLabel,
  selectedMission,
  filterStore,
  setFilter,
  isReport,
}) => {
  const classes = useStyles();
  const [error, setError] = useState([null, null]);

  useEffect(() => {
    getSeasonData();
    if (!isReport) {
      getMissionData();
    }
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      const today = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      const matchedSeason = seasonData.find((season) => {
        const startDate = season.seasonStart;
        const endDate = season.seasonEnd;
        return today >= startDate && today <= endDate;
      });
      if (matchedSeason) {
        setSelectedSeason(matchedSeason.seasonId);
        setSelectedSeasonLabel(matchedSeason.seasonName);
      } else {
        setSelectedSeason(seasonData[0]["seasonId"]);
        setSelectedSeasonLabel(seasonData[0]["seasonName"]);
      }
    }
  }, [seasonData]);

  useEffect(() => {
    let copyFilter = { ...filterStore };
    if (periodType === "mission") {
      copyFilter.assetType = "MISSION_ASSET";
    } else {
      copyFilter.assetType = "all";
    }
    setFilter(copyFilter);
  }, [periodType]);

  const handleChange = (event) => {
    setPeriodType(event.target.value);
  };

  const handleSelecSeasonChange = (event) => {
    setSelectedSeason(event.target.value);
  };

  const handleSelectMissionChange = (event) => {
    setSelectedMission(event.missionId.toString());
    setSelectedMissionLabel(event.missionName);
  };

  const isStarted = (startTime, currentTime) => {
    return startTime.isBefore(currentTime);
  };

  return (
    <div className={classes.calendarWrapper}>
      <div className={classes.root}>
        <Grid container spacing={1} direction="row">
          <Grid item>
            <div className={classes.periodLabel}>
              期間選択：
            </div>
          </Grid>
          {!isReport &&
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                name="period"
                className={classes.group}
                value={periodType}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  className={classes.label}
                  value="season"
                  control={<Radio />}
                  label="シーズン"
                />
                <FormControlLabel
                  className={classes.label}
                  value="mission"
                  control={<Radio />}
                  label="ミッション"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          }
          <Grid item>
            <FormControl className={classes.seelctControl}>
              {periodType === "season" ? (
                <Select
                  labelId="season-select-label"
                  value={selectedSeason}
                  onChange={handleSelecSeasonChange}
                  displayEmpty
                >
                  <MenuItem value="blank" disabled>
                    シーズンを選択する
                  </MenuItem>
                  {seasonData.map((s, index) => (
                    <MenuItem key={index} value={s.seasonId}>
                      {s.seasonName} : {s.seasonStart} ~ {s.seasonEnd}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                // mission dropdown
                <Autocomplete
                  id="mission-select-autocomplete"
                  options={missionData}
                  getOptionLabel={(option) => option.missionName || ""}
                  getOptionSelected={(option, value) =>
                    option.missionId.toString() === value.toString()
                  }
                  value={
                    missionData.find(
                      (mission) =>
                        mission.missionId.toString() === selectedMission
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleSelectMissionChange(newValue);
                    } else {
                      handleSelectMissionChange("blank");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`ミッションを選択する (登録 ${missionData.length}件)`}
                      className={
                        selectedMission === "blank"
                          ? classes.missionUnSelect
                          : selectedMission === "all"
                          ? classes.missionAllSelect
                          : classes.missionSelect
                      }
                    />
                  )}
                  renderOption={(option) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "450px",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {option.missionName}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        style={{ marginLeft: 14 }}
                      >
                        {moment(option.missionStart).format("YYYY-MM-DD HH:mm")}{" "}
                        ~ {moment(option.missionEnd).format("YYYY-MM-DD HH:mm")}
                      </Typography>
                    </div>
                  )}
                  getOptionDisabled={(option) => {
                    const startTime = moment(
                      option.missionStart,
                      "YYYY-MM-DD HH:mm"
                    );
                    const currentTime = moment()
                      .tz("Asia/Tokyo")
                      .format("YYYY-MM-DD HH:mm");
                    return (
                      option.missionId.toString() === "blank" ||
                      !isStarted(startTime, currentTime)
                    );
                  }}
                  className={classes.missionAllSelect}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Period.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
  seasonData: state.app.seasonData,
  missionData: state.app.missionData,
  selectedSeason: state.app.selectedSeason,
  selectedMission: state.app.selectedMission,
  filterStore: state.home.filter,
});

const mapDispatchToProps = (dispatch) => ({
  setDateRange: (param) => dispatch(setDateRange(param)),
  setPeriodType: (param) => dispatch(setPeriodType(param)),
  setSelectedSeason: (param) => dispatch(setSelectedSeason(param)),
  setSelectedSeasonLabel: (param) => dispatch(setSelectedSeasonLabel(param)),
  getSeasonData: () => dispatch(getSeasonData()),
  getMissionData: () => dispatch(getMissionData()),
  setSelectedMission: (param) => dispatch(setSelectedMission(param)),
  setSelectedMissionLabel: (param) => dispatch(setSelectedMissionLabel(param)),
  setFilter: (param) => dispatch(setFilter(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Period));
