import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Drawer,
  Divider,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardWrapper from "../containers/DashboardWrapper";
import { setCurrentPage, getReportTotalStatsData, getReportData, setFilter, getDownloadReportData, setCurrentPageNumber } from "../actions";
import Period from "../components/Filter/Period";
import FilterApplied from "../components/Filter/FilterApplied"
import Calendar from "../components/Calendar/Calendar";
import ReportTable from "../components/Table/ReportTable";
import TagConfig from "../config/TagConfig";
import { CSVLink } from "react-csv";
import moment from "moment";
import "moment-timezone";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    marginTop: theme.spacing(1),
    width: "100%%",
  },
  contentGridSlim: {
    marginTop: theme.spacing(1),
    width: "90%",
  },
  root: {
    marginTop: 32,
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  date: {
    marginBottom: 12,
  },
  filtered: {
    marginTop: 10,
    marginLeft: 16,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: 62,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  tagTextWrapper: {
    padding: "16px",
  },
  tagTip: {
    fontWeight: "bold",
    marginBottom: 16,
  },
  tagText: {
    fontSize: "14px",
    marginBottom: 16,
  }
}));

const Report = ({
  setCurrentPage,
  setCurrentPageNumber,
  getReportTotalStatsData,
  getReportData,
  setFilter,
  currentPageNumber,
  periodType,
  filterStore,
  seasonData,
  selectedSeasonLabel,
  getDownloadReportData,
  downloadData,
  filteredTotal,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [downloadImage, setDownloadImage] = useState([]);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [currentJstTime, setCurrentJstTime] = useState("");
  const csvLinkRef = useRef(null);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setCurrentPageNumber(1);
    let copyFilter =  {...filterStore};
    copyFilter.tag = [];
    setFilter(copyFilter);
  }, []);

  useEffect(() => {
    if (seasonData.length > 0) {
      getReportData({
        pageNumber: currentPageNumber,
        sort: "default",
      });
    }
  }, [currentPageNumber, seasonData]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
  }, [periodType]);

  useEffect(() => {
    if (downloadData.length > 0 && !isDownloaded) {
      csvLinkRef.current.link.click(); // CSVLink のクリックイベントを発火
      setIsDownloaded(true);
    }
  }, [downloadData, isDownloaded, currentJstTime]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleImageSelect = (event, value) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // 選択時に配列へ追加
      setDownloadImage((prev) => [...prev, value]);
    } else {
      // 解除時に配列から削除
      setDownloadImage((prev) => prev.filter((url) => url !== value));
    }
  };

  const handleReportDownload = () => {
    setIsDownloaded(false);
    getDownloadReportData();
    setCurrentJstTime(moment().tz("Asia/Tokyo").format("YYYYMMDDHHmmss"));
  };

  return (
    <DashboardWrapper>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid
            container
            justifyContent={"center"}
            className={open
              ? classes.contentGridSlim
              : classes.contentGrid
            }
          >
            <Grid item xs={12} lg={10}>
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                className={open
                  ? classes.contentGridSlim
                  : classes.contentGrid
                }
                >
                <Grid item>
                  <Period isReport={true} />
                </Grid>
                <Grid item>
                  <Calendar />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={10} className={classes.filtered} justifyContent={"space-between"}>
              <Grid
                container
                direction="row"
                justifyContent={"space-between"}
                alignItems="flex-end"
                className={open
                  ? classes.contentGridSlim
                  : classes.contentGrid
                }
              >
                <Grid item>
                  <FilterApplied />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleReportDownload}
                    disabled={filteredTotal === 0 || filteredTotal === "--"}
                  >
                    報告一括ダウンロード(CSV)
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={10} className={classes.filtered}>
              <ReportTable
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleImageSelect={handleImageSelect}
                downloadImage={downloadImage}
                setDownloadImage={setDownloadImage}
                open={open}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.tagTextWrapper}>
          {tagData.length > 0 &&
            <>
              <div className={classes.tagTip}>
                {periodType === "season" ? "シーズン" : "ミッション"}結果タグ説明
              </div>
              {tagData.length > 0 && tagData.map((row, index) => (
                <div className={classes.tagText} key={index}>{row["tip"]}</div>
              ))}
            </>
          }
        </div>
      </Drawer>
      <CSVLink
        data={downloadData}
        filename={`【ピクトレ】報告_${selectedSeasonLabel}_${currentJstTime}.csv`}
        className="hidden"
        ref={csvLinkRef}
      />
    </DashboardWrapper>
  );
};

Report.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  // currentPageNumber: state.report.currentPageNumber,
  currentPageNumber: state.home.currentPageNumber,
  periodType: state.app.periodType,
  filterStore: state.report.filter,
  filteredTotal: state.report.filteredTotal,
  seasonData: state.app.seasonData,
  selectedSeasonLabel: state.app.selectedSeasonLabel,
  downloadData: state.report.downloadData,
  allImageDataByFilename: state.image.allImageDataByFilename,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
  getReportTotalStatsData: () => dispatch(getReportTotalStatsData()),
  getReportData: (param) => dispatch(getReportData(param)),
  getDownloadReportData: (param) => dispatch(getDownloadReportData(param)),
  setFilter: (param) => dispatch(setFilter(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
