import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Button,
} from "@material-ui/core";
const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  viewerBtn: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  fontIcon: {
    marginTop: -6,
    marginLeft: 8,
  }
}));

export default function ImageCard({ data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const openImageInNewTab = (fileName) => {
    let filePath = fileName.split(".jpg")[0];
    const BASE_URL = window.location.origin;
    const urlPath = `${BASE_URL}/viewer/${filePath}`;
    window.open(urlPath, '_blank');
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`撮影者：${data.name}`}
        subheader={new Date(data.occurred_datetime_jst).toLocaleString("ja-JP")}
      />
      <CardMedia
        className={classes.media}
        image={`${S3_BUCKET_BASE_URL}/${data.object_key}`}
        title=""
      />
      <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Button
          className={classes.viewerBtn}
          variant="default"
          onClick={() => openImageInNewTab(data.file_name)}
          size="small"
        >
          ビュワーツールで表示する
          <OpenInNewIcon className={classes.fontIcon} fontSize="small" />
        </Button>
      </CardContent>
      {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}
    </Card>
  );
}
