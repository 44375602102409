import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { REPORT, ENDPOINT } from '../constants';
import TepcoConfig from "../config/TepcoConfig";

import {
  setPageTopLoader,
  setReportTotalStatsData,
  setReportData,
  setFilteredTotal,
  setReportFilteredTotal,
  setDownloadReportData,
  setReportTagFilterData,
  setImageDataByReportId,
  setImageDataByOtherUsers,
} from '../actions';

import {
  getJwtToken,
  getDataFromKpiDash,
} from '../api';

const getLocalStorageItem = (key) => {
    return localStorage.getItem(key);
};

const S3_BUCKET_BASE_URL = process.env.REACT_APP_S3_BUCKET_BASE_URL;

const formatToJST = (isoString) => {
  // "2025-02-19T16:17:36.833Z" → "2025/02/19 16:17:36"
  const [datePart, timePart] = isoString.split("T"); // ["2025-02-19", "16:17:36.833Z"]
  const timeOnly = timePart.split(".")[0]; // "16:17:36"

  return `${datePart.replace(/-/g, "/")} ${timeOnly}`; // "-" を "/" に変換
};

const sortReportTags = (reportTagListDataParsed) => {
  return reportTagListDataParsed.sort((a, b) => {
    return (
      TepcoConfig.reportTagSort.indexOf(a.label) -
      TepcoConfig.reportTagSort.indexOf(b.label)
    );
  });
};

const sortByTepcoOrder = (array) => {
  return [...array].sort((a, b) => {
    const indexA = TepcoConfig.reportTagSort.indexOf(a);
    const indexB = TepcoConfig.reportTagSort.indexOf(b);
    return (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB);
  });
};

export const periodType = state => state.app.periodType;
export const selectedSeason = state => state.app.selectedSeason;
export const selectedMission = state => state.app.selectedMission;
export const startDate = state => state.app.dateRange[0];
export const endDate = state => state.app.dateRange[1];
export const filter = state => state.report.filter;
export const filterType = state => state.app.filterType;

export function* handleReportData(action) {
  try {
    yield put(setPageTopLoader(true));
    yield put(setReportFilteredTotal("--"));
    const env = process.env.REACT_APP_ENV;
    const type = yield select(periodType);
    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format('YYYY-MM-DD');
    dateTo = moment(dateTo).tz("Asia/Tokyo").format('YYYY-MM-DD');
    const assetFilter = yield select(filter);
    const seasonIdSelected = yield select(selectedSeason);
    const pageNumber = action.payload.pageNumber === undefined ? 1 : action.payload.pageNumber || 1;
    const orderValue = assetFilter.order == "desc" ? "DESC" : "ASC";
    const equipmentNumber = assetFilter.assetId === null ? false : assetFilter.assetId;
    const signNumber = assetFilter.signNumber === null ? false : assetFilter.signNumber;
    let assetType = assetFilter.assetType === "all" ? false : assetFilter.assetType;
    const manageGroup = assetFilter.manageGroup.key === "all" ? false : assetFilter.manageGroup.value;

    const itemsPerPage = 10;
    const offset = (pageNumber - 1) * itemsPerPage;

    // 地上設備 x 2種類
    let assetType2 = "";
    if (assetType && assetType.includes("GROUND_EQUIPMENT")) {
      assetType2 = assetType.split("-")[1];
      assetType = assetType.split("-")[0];
    }

    const seasonIdQuery = type === "season" && seasonIdSelected !== "all"
      ? `AND ppa.game_space_id = '${seasonIdSelected}'` : "";

    const assetIdQuery = equipmentNumber
      ? `AND padtep.equipment_number = '${equipmentNumber}'` : "";

    const signNumberQuery = signNumber
      ? `AND padtep.sign_number = '${signNumber}'` : "";

    let assetTypeQuery = assetType && assetType !== "POWER_POLE_HAS_PROTECTIVE_TUBE_ALL"
      ? `AND pa.type = '${assetType}'` : "";

    const assetTypeQuery2 = assetType2 !== "" ? `AND padtep.equipment_code = '${assetType2}'` : "";

    // 防護管付き電柱
    let bogokanJoinQuery = ``;
    let bogokanWhereQuery = ``;
    if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_ALL")) {
      bogokanJoinQuery = `JOIN power_pole pp ON rr.power_asset_id = pp.id`
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE'`
    } else if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC")) {
      bogokanJoinQuery = `
        JOIN power_asset_angle_of_view_name_v2 paaovn ON paaovn.power_asset_photography_target_id = ppa.power_asset_photography_target_id
        AND paaovn.language_code = 'JA'
        JOIN power_pole pp ON rr.power_asset_id = pp.id
      `;
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE' AND paaovn.angle_of_view_name like '% - 防護管%'`
      assetTypeQuery = "";
    }

    // 配電保守グループ
    let managementGroupQuery = ``;
    if (manageGroup) {
      const manageGroupCommaSeparated = manageGroup.join("','");
      managementGroupQuery = `AND padtep.place_code IN ('${manageGroupCommaSeparated}')`;
    }

    const dateRagenQuery = type === "season"
      ? `AND CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') >= '${dateFrom} 00:00:00'
         AND CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') <= '${dateTo} 23:59:59'`
      : "";

    const tagQuery = assetFilter.tag.length > 0
      ? `AND rcd.tag_label IN ('${assetFilter.tag.join("','")}')` : "";

    // Get count by tag name within a selected season
    let sqlQueryReportCountByTagName = {};
    sqlQueryReportCountByTagName.query = `
      SELECT
          COUNT(DISTINCT rr.id) as total_count,
          rcd.*,
          rtdn.*,
          rr.*
      FROM
          report rr
      JOIN
          report_content_detail rcd ON rr.id = rcd.report_id
      JOIN
          report_tag_display_name rtdn ON rtdn.report_tag_id = rcd.tag_id
      JOIN player_state ps ON rr.player_id = ps.player_id
      JOIN power_asset_detail_of_tepco padtep ON rr.power_asset_id = padtep.power_asset_id
      JOIN photo_of_power_asset ppa ON ppa.power_asset_id = rr.power_asset_id
      WHERE rtdn.language_code = 'JA' AND rr.status = 'REGISTERED'
      ${dateRagenQuery}
      GROUP BY rcd.tag_label
      ORDER BY total_count DESC;
  `;

    let sqlQueryReportData = {};
    // SXI provided
    sqlQueryReportData.query = `
      SELECT
          rr.id,
          ps.name,
          padtep.place_code,
          padtep.sign_number,
          padtep.sign_name,
          padtep.equipment_code,
          padtep.equipment_number,
          CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
          GROUP_CONCAT(DISTINCT rcd.tag_id) AS tag_ids,
          GROUP_CONCAT(DISTINCT rcd.tag_label) AS tag_label,
          rcd.additional_comment AS comment,
          GROUP_CONCAT(DISTINCT rtdn.name) AS tag_display_names,
          GROUP_CONCAT(DISTINCT rp.object_key) AS photo_urls
      FROM
          report rr
      JOIN
          report_content_detail rcd ON rr.id = rcd.report_id
      JOIN
          report_tag_display_name rtdn ON rtdn.report_tag_id = rcd.tag_id
      LEFT OUTER JOIN
          report_photograph rp ON rp.report_id = rr.id
      JOIN player_state ps ON rr.player_id = ps.player_id
      JOIN power_asset_detail_of_tepco padtep ON rr.power_asset_id = padtep.power_asset_id
      JOIN power_asset pa ON pa.id = padtep.power_asset_id
      JOIN photo_of_power_asset ppa ON ppa.power_asset_id = rr.power_asset_id
      ${bogokanJoinQuery}
      WHERE rtdn.language_code = 'JA' AND rr.status = 'REGISTERED'
      ${dateRagenQuery}
      ${assetIdQuery}
      ${signNumberQuery}
      ${managementGroupQuery}
      ${tagQuery}
      ${assetTypeQuery}
      ${assetTypeQuery2}
      ${bogokanWhereQuery}
      GROUP BY rr.id
      ORDER BY rr.report_datetime ${orderValue}
      LIMIT ${itemsPerPage}
      OFFSET ${offset};
    `;

    let sqlQueryReportDataTest = {};
    sqlQueryReportDataTest.query = `
      select * from report
      where status = 'REGISTERED'
      order by report_datetime DESC;
    `;

    let sqlQueryReportCount = {};
    sqlQueryReportCount.query = `
      SELECT
          COUNT(DISTINCT rr.id) as total_count
      FROM
          report rr
      JOIN
          report_content_detail rcd ON rr.id = rcd.report_id
      JOIN
          report_tag_display_name rtdn ON rtdn.report_tag_id = rcd.tag_id
      LEFT OUTER JOIN
          report_photograph rp ON rp.report_id = rr.id
      JOIN player_state ps ON rr.player_id = ps.player_id
      JOIN power_asset_detail_of_tepco padtep ON rr.power_asset_id = padtep.power_asset_id
      JOIN power_asset pa ON pa.id = padtep.power_asset_id
      JOIN photo_of_power_asset ppa ON ppa.power_asset_id = rr.power_asset_id
      ${bogokanJoinQuery}
      WHERE rtdn.language_code = 'JA' AND rr.status = 'REGISTERED'
      ${dateRagenQuery}
      ${assetIdQuery}
      ${signNumberQuery}
      ${managementGroupQuery}
      ${tagQuery}
      ${assetTypeQuery}
      ${assetTypeQuery2}
      ${bogokanWhereQuery}
    `;

    const [tagtListDataTemp, reportDataListTemp, reportDataTotalCountTemp] = yield all([
      call(getDataFromKpiDash, sqlQueryReportCountByTagName, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryReportData, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryReportCount, ENDPOINT.GET_DATA_API),
    ]);
    // console.log("LOG sxi reportDataTotalCountTemp:", reportDataTotalCountTemp);
    // Tag filter data with count
    const reportTagListData = JSON.parse(tagtListDataTemp.body);
    let reportTagListDataParsed = [], reportTagFilterEachData = {};
    _.each(reportTagListData, function(tagData) {
      reportTagFilterEachData.tag_id = tagData.tag_id;
      reportTagFilterEachData.label = tagData.tag_label;
      reportTagFilterEachData.tip = tagData.name;
      reportTagFilterEachData.count = tagData.total_count;
      reportTagFilterEachData.value = tagData.tag_label;
      reportTagListDataParsed.push(reportTagFilterEachData);
      reportTagFilterEachData = {};
    });
    let reportTagListDataTepcoSortParsed = sortReportTags(reportTagListDataParsed);
    // console.log("LOG: reportTagListDataParsed:", reportTagListDataParsed);
    // console.log("LOG: reportTagListDataTepcoSortParsed:", reportTagListDataTepcoSortParsed);
    // table and total count data
    const top10ReportData = JSON.parse(reportDataListTemp.body);
    const countReportData = JSON.parse(reportDataTotalCountTemp.body);
    console.log("LOG sxi top10ReportData:", top10ReportData);
    // console.log("LOG sxi total_count:", countReportData);

    let photoPathArray = [], photoFileNameArray = [], allPhotoUrl = [], tagLabelUnique = [], tagDisplayUnique = [];
    _.each(top10ReportData, function(reportData) {
      const photoUrl = reportData.photo_urls !== null ? reportData.photo_urls.split(",") : [false];
      const fileName = reportData.photo_urls !== null ? photoUrl[0].split("Report/")[1] : false;
      let tagUniqueArray = reportData.tag_label.split(",");
      let tagDisplayUniqueArray = reportData.tag_display_names.split(",");
      if (tagDisplayUniqueArray.indexOf("その他") !== -1) {
        const otherIndex = tagDisplayUniqueArray.indexOf("その他");
        tagDisplayUniqueArray[otherIndex] = reportData.comment;
      }
      photoPathArray.push(photoUrl[0]); // have multiple pic urls but use 1st one.
      photoFileNameArray.push(fileName);
      allPhotoUrl.push(photoUrl);
      tagLabelUnique.push([...new Set(tagUniqueArray)]);
      tagDisplayUnique.push([...new Set(tagDisplayUniqueArray)]);
    });
    // console.log("HEY tagLabelUnique:", tagLabelUnique)
    // console.log("HEY tagDisplayUnique:", tagDisplayUnique)
    const reportAllData = top10ReportData.map((photoData, index) => ({
      reportId: photoData.id,
      signNumber: photoData.sign_number,
      signName: photoData.sign_name !== null && photoData.sign_name !== undefined ? photoData.sign_name : "--",
      name: photoData.equipment_number,
      placeCode: photoData.place_code !== null ? photoData.place_code : "--",
      tag: tagLabelUnique[index], //["対象無し", "位置ズレ", "撮影困難"],
      tagTip: tagDisplayUnique[index] == "その他" ? photoData.comment : tagDisplayUnique[index],
      user: photoData.name,
      fileUrl: photoPathArray[index] ? `${S3_BUCKET_BASE_URL}/${photoPathArray[index]}` : false,
      fileName: photoPathArray[index] ? photoFileNameArray[index].split(".jpg")[0] : false,
      occurred_datetime: photoData.occurred_datetime_jst,
      allPhotoUrl: allPhotoUrl[index],
    }));
    // console.log("LOG sxi reportAllData:", reportAllData);
    // console.log("LOG sxi reportTagListData:", reportTagListData);
    yield put(setReportFilteredTotal(countReportData[0] === undefined ? 0 : countReportData[0]["total_count"]));
    yield put(setReportData(reportAllData.length === 0 ? [] : reportAllData));
    yield put(setReportTagFilterData(reportTagListDataTepcoSortParsed));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleReportDownloadData(action) {
  try {
    yield put(setPageTopLoader(true));
    yield put(setDownloadReportData([]));
    const type = yield select(periodType);
    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format('YYYY-MM-DD');
    dateTo = moment(dateTo).tz("Asia/Tokyo").format('YYYY-MM-DD');
    const assetFilter = yield select(filter);
    const seasonIdSelected = yield select(selectedSeason);
    const orderValue = assetFilter.order == "desc" ? "DESC" : "ASC";
    const equipmentNumber = assetFilter.assetId === null ? false : assetFilter.assetId;
    const signNumber = assetFilter.signNumber === null ? false : assetFilter.signNumber;
    let assetType = assetFilter.assetType === "all" ? false : assetFilter.assetType;
    const manageGroup = assetFilter.manageGroup.key === "all" ? false : assetFilter.manageGroup.value;

    // 地上設備 x 2種類
    let assetType2 = "";
    if (assetType && assetType.includes("GROUND_EQUIPMENT")) {
      assetType2 = assetType.split("-")[1];
      assetType = assetType.split("-")[0];
    }

    const seasonIdQuery = type === "season" && seasonIdSelected !== "all"
      ? `AND ppa.game_space_id = '${seasonIdSelected}'` : "";

    const assetIdQuery = equipmentNumber
      ? `AND padtep.equipment_number = '${equipmentNumber}'` : "";

    const signNumberQuery = signNumber
      ? `AND padtep.sign_number = '${signNumber}'` : "";

    let assetTypeQuery = assetType && assetType !== "POWER_POLE_HAS_PROTECTIVE_TUBE_ALL"
      ? `AND pa.type = '${assetType}'` : "";

    const assetTypeQuery2 = assetType2 !== "" ? `AND padtep.equipment_code = '${assetType2}'` : "";

    // 防護管付き電柱
    let bogokanJoinQuery = ``;
    let bogokanWhereQuery = ``;
    if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_ALL")) {
      bogokanJoinQuery = `JOIN power_pole pp ON padtep.power_asset_id = pp.id`
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE'`
    } else if (assetType && assetType.includes("POWER_POLE_HAS_PROTECTIVE_TUBE_WITH_PIC")) {
      bogokanJoinQuery = `
        JOIN power_asset_angle_of_view_name_v2 paaovn ON paaovn.power_asset_photography_target_id = ppa.power_asset_photography_target_id
        AND paaovn.language_code = 'JA'
        JOIN power_pole pp ON rr.power_asset_id = pp.id
      `;
      bogokanWhereQuery = `AND pp.power_pole_type = 'HAS_PROTECTIVE_TUBE' AND paaovn.angle_of_view_name like '% - 防護管%'`
      assetTypeQuery = "";
    }

    // 配電保守グループ
    let managementGroupQuery = ``;
    if (manageGroup) {
      const manageGroupCommaSeparated = manageGroup.join("','");
      managementGroupQuery = `AND padtep.place_code IN ('${manageGroupCommaSeparated}')`;
    }

    const dateRagenQuery = type === "season"
      ? `AND CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') >= '${dateFrom} 00:00:00'
         AND CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') <= '${dateTo} 23:59:59'`
      : "";

    const tagQuery = assetFilter.tag.length > 0
      ? `AND rcd.tag_label IN ('${assetFilter.tag.join("','")}')` : "";

    let sqlQuery = {};
    sqlQuery.query = `
      SELECT
          rr.id,
          ps.name,
          padtep.place_code,
          padtep.sign_number,
          padtep.sign_name,
          padtep.equipment_code,
          padtep.equipment_number,
          CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
          rcd.tag_id AS tag_ids,
          rcd.tag_label AS tag_label,
          rcd.additional_comment AS comment,
          rtdn.name AS tag_display_names,
          rp.object_key AS photo_urls
      FROM
          report rr
      JOIN
          report_content_detail rcd ON rr.id = rcd.report_id
      JOIN
          report_tag_display_name rtdn ON rtdn.report_tag_id = rcd.tag_id
      LEFT OUTER JOIN
          report_photograph rp ON rp.report_id = rr.id
      JOIN player_state ps ON rr.player_id = ps.player_id
      JOIN power_asset_detail_of_tepco padtep ON rr.power_asset_id = padtep.power_asset_id
      JOIN power_asset pa ON pa.id = padtep.power_asset_id
      JOIN photo_of_power_asset ppa ON ppa.power_asset_id = rr.power_asset_id
      ${bogokanJoinQuery}
      WHERE rtdn.language_code = 'JA' AND rr.status = 'REGISTERED'
      ${dateRagenQuery}
      ${assetIdQuery}
      ${signNumberQuery}
      ${managementGroupQuery}
      ${tagQuery}
      ${assetTypeQuery}
      ${assetTypeQuery2}
      ${bogokanWhereQuery}
      GROUP BY occurred_datetime_jst, tag_label
      ORDER BY rr.report_datetime ${orderValue};
    `;

    const [reportDownloadDataTemp] = yield all([
      call(getDataFromKpiDash, sqlQuery, ENDPOINT.GET_DATA_API),
    ]);
    const downloadCsvData = JSON.parse(reportDownloadDataTemp.body);

    const reportDownloadArray = [
      ["タイムスタンプ", "スキーマ番号", "標識番号", "標識名", "設備コード", "設備番号", "プレイヤー名", "報告タグ", "報告内容"], // ヘッダー
      ...downloadCsvData.map((item, index) => [
        formatToJST(item.occurred_datetime_jst),
        item.place_code,
        item.sign_number,
        item.sign_name,
        item.equipment_code,
        item.equipment_number,
        item.name,
        item.tag_label,
        (item.tag_label === "その他" ? item.comment : item.tag_display_names)
      ]), // 行データ
    ];

    yield put(setDownloadReportData(reportDownloadArray));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleReportDataByReportId(action) {
  try {
    // const jwtToken = yield call(getJwtToken);
    // const env = process.env.REACT_APP_ENV;
    yield put(setPageTopLoader(true));
    yield put(setFilteredTotal("--"));
    const type = yield select(periodType);
    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).tz("Asia/Tokyo").format('YYYY-MM-DD');
    dateTo = moment(dateTo).tz("Asia/Tokyo").format('YYYY-MM-DD');
    const reportId = action.payload;

    let sqlQueryReportData = {};
    sqlQueryReportData.query = `
      SELECT
          rr.id,
          rr.power_asset_id,
          rr.game_space_id,
          ps.name,
          padtep.place_code,
          padtep.sign_number,
          padtep.sign_name,
          padtep.equipment_code,
          padtep.equipment_number,
          CONVERT_TZ(rr.report_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
          GROUP_CONCAT(DISTINCT rcd.tag_id) AS tag_ids,
          GROUP_CONCAT(DISTINCT rcd.tag_label) AS tag_label,
          rcd.additional_comment AS comment,
          GROUP_CONCAT(DISTINCT rtdn.name) AS tag_display_names,
          GROUP_CONCAT(DISTINCT rp.object_key) AS photo_urls
      FROM
          report rr
      JOIN
          report_content_detail rcd ON rr.id = rcd.report_id
      JOIN
          report_tag_display_name rtdn ON rtdn.report_tag_id = rcd.tag_id
      LEFT OUTER JOIN
          report_photograph rp ON rp.report_id = rr.id
      join player_state ps ON rr.player_id = ps.player_id
      join power_asset_detail_of_tepco padtep ON rr.power_asset_id = padtep.power_asset_id
      where rtdn.language_code = 'JA' AND rr.status = 'REGISTERED' AND rr.id = '${reportId}'
      GROUP by rr.id
    `;

    const [reportDataListTemp] = yield all([
      call(getDataFromKpiDash, sqlQueryReportData, ENDPOINT.GET_DATA_API),
    ]);

    // report data
    const reportData = JSON.parse(reportDataListTemp.body);
    let photoPathArray = [], photoFileNameArray = [], allPhotoUrl = [], tagLabelUnique = [], tagLabelDisplay = [];
    _.each(reportData, function(reportData) {
      const photoUrl = reportData.photo_urls !== null ? reportData.photo_urls.split(",") : [false];
      const fileName = reportData.photo_urls !== null ? photoUrl[0].split("Report/")[1] : false;
      let tagUniqueArray = reportData.tag_label.split(",");
      let tagDisplayArray = reportData.tag_display_names.split(",");
      photoPathArray.push(photoUrl[0]); // have multiple pic urls but use 1st one.
      photoFileNameArray.push(fileName);
      allPhotoUrl.push(photoUrl);
      tagLabelUnique.push([...new Set(tagUniqueArray)]);
      tagLabelDisplay.push([...new Set(tagDisplayArray)]);
    });
    // const sortedTags = sortByTepcoOrder(tagLabelUnique[0]);
    // const sortedTagsDisplay = sortByTepcoOrder(tagLabelDisplay[0]);
    const reportFinalData = reportData.map((photoData, index) => ({
      reportId: photoData.id,
      signNumber: photoData.sign_number,
      signName: photoData.sign_name !== null && photoData.sign_name !== undefined ? photoData.sign_name : "--",
      name: photoData.equipment_number,
      placeCode: photoData.place_code !== null ? photoData.place_code : "--",
      tag: tagLabelUnique[0],//sortedTags, //["対象無し", "位置ズレ", "撮影困難"], sort to TEPCO order
      tagDisplay: tagLabelDisplay[0], // sortedTagsDisplay, //tagLabelDisplay[0], @todo, sort to TEPCO order
      comment: photoData.comment === null ? false : photoData.comment,
      user: photoData.name,
      fileUrl: photoPathArray[index] ? `${S3_BUCKET_BASE_URL}/${photoPathArray[index]}` : false,
      fileName: photoPathArray[index] ? photoFileNameArray[index].split(".jpg")[0] : false,
      occurred_datetime_jst: photoData.occurred_datetime_jst,
      allPhotoUrl: allPhotoUrl[index],
    }));

    if (reportFinalData.length > 0 && !reportFinalData[0].fileUrl) {
      let sqlAssetQuery = {};
      sqlAssetQuery.query = `
        SELECT
            ppa.*,
            gs.*,
            gt.*,
            pae.occurred_datetime AS occurred_datetime,
            CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
            ps.*,
            padtep.*,
            rrpb.review_request_id
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            power_asset_detail_of_tepco padtep ON pae.power_asset_id = padtep.power_asset_id
        JOIN
            review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN
          	power_asset pa ON pa.id = pae.power_asset_id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND pae.power_asset_id = '${reportData[0]["power_asset_id"]}'
            AND pae.game_space_id = '${reportData[0]["game_space_id"]}'
        GROUP BY
            pae.power_asset_id, ps.player_id, occurred_datetime
        ORDER BY occurred_datetime DESC
        LIMIT 4;
      `;
      const [reportDataPhotoTemp] = yield all([
        call(getDataFromKpiDash, sqlAssetQuery, ENDPOINT.GET_DATA_API),
      ]);
      const reporExtratData = JSON.parse(reportDataPhotoTemp.body);
      yield put(setImageDataByOtherUsers(reporExtratData.length === 0 ? [] : reporExtratData));
    }

    yield put(setImageDataByReportId(reportFinalData.length === 0 ? [] : reportFinalData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(REPORT.GET_REPORT_DATA, handleReportData);
  yield takeEvery(REPORT.GET_DOWNLOAD_REPORT_DATA, handleReportDownloadData);
  yield takeEvery(REPORT.GET_IMAGE_DATA_BY_REPORT_ID, handleReportDataByReportId);
}