import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Typography, TextField, Button, Tooltip, Checkbox } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, Chip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import HelpIcon from "@material-ui/icons/Help";
import { setPeriodType, setCurrentPageNumber, setSelectedTag, setSavedSelectedTagData, } from "../../actions";
import _ from "lodash";
import TagConfig from "../../config/TagConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    tableWrapper: {
      width: "100%",
    },
    tableWrapperSlim: {
      width: "89%",
    },
    table: {
      width: "100%",
    },
    tableHead: {
      height: 40,
      backgroundColor: "#fafafa",
    },
    tableRow: {
      height: 126,
      '&:hover': {
        backgroundColor: theme.palette.action.hover, // Use a light grey color on hover
      },
    },
    tableRowNone: {
      height: 126,
    },
    noDataCell: {
      textAlign: "center",
      padding: theme.spacing(3),
      fontSize: 14,
      color: theme.palette.text.secondary,
    },
    thumb: {
      width: 80,
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    imagePopup: {
      position: "fixed",  // Change to fixed so it stays within the viewport
      border: "1px solid #ccc",
      backgroundColor: "#fff",
      padding: theme.spacing(1),
      boxShadow: theme.shadows[3],
      zIndex: 1000,
    },
    tagUnSelect: {
      marginTop: theme.spacing(-2.6),
      fontSize: 9,
      "& .MuiInputLabel-root": {
        fontSize: "14px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "14px", // Customize input text size (if needed)
      }
    },
    tagSelect: {
      minWidth: 300,
      maxWidth: 300,
      marginTop: theme.spacing(-2.6),
      "& .MuiInputLabel-root": {
        fontSize: "14px", // Smaller font size for the label (placeholder)
      },
      "& .MuiInputBase-input": {
        fontSize: "14px", // Customize input text size (if needed)
      }
    },
    iconTooltip: {
      fontSize: 18,
      marginTop: -4,
      marginLeft: 4,
    },
    tagWrapper: {
      display: "flex",
    },
    saveButton: {
      fontWeight: "medium",
      height: 30,
      marginTop: -4,
      marginLeft: 16,
      paddingTop: 8,
    },
    tooltip: {
      fontSize: "12px", // フォントサイズを小さく
      paddingTop: "8px", // Tooltip の余白調整
    },
    icon: {
      paddingTop: "4px", // アイコンの位置調整
    },
  })
);

const PhotoTable = ({
  setRange,
  dateRange,
  periodType,
  setPeriodType,
  homeImageData,
  totalAssetStats,
  setCurrentPageNumber,
  filteredTotal,
  setSelectedTag,
  setSavedSelectedTagData,
  selectedTag,
  handleDrawerOpen,
  handleDrawerClose,
  handleImageSelect,
  tagSavingLoader,
  open,
  downloadImage,
  setDownloadImage,
  filterType,
  reportData,
  activeDownload,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const itemsPerPage = 10;
  const popupWidth = 300;
  const popupHeight = 300;

  useEffect(() => {
    if (typeof filteredTotal === "number") {
      setTotalAssets(filteredTotal);
    }
  }, [filteredTotal]);

  useEffect(() => {
    if (periodType === "season") {
      setTagData(TagConfig.seasonTag);
    } else {
      setTagData(TagConfig.missionTagB.concat(TagConfig.missionTagC));
    }
  }, [periodType]);

  useEffect(() => {
    const dataForTable = filterType === "photo" ? homeImageData : reportData;
    if (dataForTable.length > 0) {
      let imageDataObj = {}, imageArray = [];
      _.each(dataForTable, function(data) {
        imageDataObj.signNumber = data.signNumber;
        imageDataObj.signName = data.signName;
        imageDataObj.name = data.name;
        imageDataObj.placeCode = data.placeCode;
        imageDataObj.assetNum = data.assetNum;
        imageDataObj.fileName = data.fileName;
        imageDataObj.tag = data.tag;
        imageDataObj.user = data.user;
        imageDataObj.fileUrl = data.fileUrl;
        const dateObj = new Date( data.occurred_datetime);
        imageDataObj.dateTime = dateObj.toISOString().slice(0, 19).replace("T", " ");
        imageArray.push(imageDataObj);
        imageDataObj = {};
      });
      setTableData(imageArray);
    } else {
      setTableData([]);
      setTotalAssets(0);
    }
  }, [homeImageData, reportData, filterType]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setCurrentPageNumber(value);
  };

  const getViewUrlView = (photoId) => `/viewer/${photoId}`;

  const pageCount = Math.ceil(totalAssets / itemsPerPage);

  const handleMouseEnter = (imageUrl, e) => {
    setHoveredImage(imageUrl);
    setHoverPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseMove = (e) => {
    setHoverPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  const calculatePopupTop = (y) => {
    const viewportHeight = window.innerHeight;
    const top = y - popupHeight / 2;
    if (top < 0) return 0;
    if (top + popupHeight > viewportHeight) return viewportHeight - popupHeight;
    return top;
  };

  const calculatePopupLeft = (x) => {
    const viewportWidth = window.innerWidth;
    const left = x + 20;
    if (left + popupWidth > viewportWidth) return viewportWidth - popupWidth;
    return left;
  };

  const handleSelectTagChange = (tags, row) => {
    const savingTagData = {
      tags: tags,  // Array of selected tags
      fileName: row.fileName //row.fileName
    };
    console.log("savingTagData :", savingTagData);
    setSelectedTag(savingTagData);
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent={"center"}
        className={open
          ? classes.tableWrapperSlim
          : classes.tableWrapper
        }
      >
        <Grid item className={classes.filterItem}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow className={classes.stickyHeader}>
                {activeDownload &&
                  <TableCell align="center">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                        <Checkbox
                          checked={downloadImage.length > 0 && downloadImage.length === tableData.length} // 全選択判定
                          indeterminate={downloadImage.length > 0 && downloadImage.length < tableData.length} // 部分選択時
                          onChange={(e) => {
                            if (e.target.checked) {
                              // 全選択
                              setDownloadImage(tableData.map((row) => row.fileUrl));
                            } else {
                              // リセット
                              setDownloadImage([]);
                            }
                          }}
                          inputProps={{ "aria-label": "select all" }}
                        />

                      <Tooltip
                        title={
                          <div>
                            一括ダウンロード選択<br />
                            <small>※最大10件まで選択可能です</small>
                          </div>
                        }
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <HelpIcon
                          fontSize="small"
                          className={classes.icon}
                        />
                      </Tooltip>
                    </div>
                  </TableCell>
                  }
                  <TableCell className={classes.thumb}></TableCell>
                  <TableCell align="center">標識番号</TableCell>
                  <TableCell align="center">標識名</TableCell>
                  <TableCell align="center">設備番号</TableCell>
                  <TableCell align="center">スキーマ番号</TableCell>
                  {filterType === "photo" &&
                    <TableCell align="center">
                      結果入力
                      <IconButton
                        className={classes.iconTooltip}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                        disableRipple={true}
                        disableFocusRipple={true}
                      >
                      <HelpIcon
                        fontSize="small"
                      />
                      </IconButton>
                    </TableCell>
                  }
                  {filterType === "report" &&
                    <TableCell align="center">
                      報告タグ
                    </TableCell>
                  }
                  <TableCell align="center">{filterType === "photo" ? "撮影者" : "報告者"}</TableCell>
                  <TableCell align="center">{filterType === "photo" ? "撮影日時" : "報告日時"}</TableCell>
                  <TableCell align="center">詳細</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 && tableData.map((row, index) => (
                  <TableRow className={classes.tableRow} key={index}>
                    {activeDownload &&
                    <TableCell style={{width:"2%"}} align="center">
                      <Checkbox
                        checked={downloadImage.includes(row.fileUrl)}
                        onChange={(e) => handleImageSelect(e, row.fileUrl)}
                        inputProps={{ "download-image": "primary checkbox" }}
                        disabled={downloadImage.length >= 10}
                      />
                    </TableCell>
                    }
                    <TableCell component="th" scope="row" style={{width:"10%"}}>
                      <Link
                        to={getViewUrlView(row.fileName)}
                        target="_blank">
                          <img
                            src={row.fileUrl}
                            height="90"
                            width="90"
                            onMouseEnter={(e) => handleMouseEnter(row.fileUrl, e)}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                            style={{
                              borderRadius: 6,
                              opacity: row.fileName == undefined ? 0 : 1,
                              transition: 'opacity 0.5s ease-in-out',
                            }}
                          />
                      </Link>
                    </TableCell>
                    <TableCell style={{width:"7%"}} align="center">{row.signNumber}</TableCell>
                    <TableCell style={{width:"7%"}} align="center">{row.signName}</TableCell>
                    <TableCell style={{width:"7%"}} align="center">{row.name}</TableCell>
                    <TableCell style={{width:"7%"}} align="center">{row.placeCode}</TableCell>
                    <TableCell style={{width:"30%"}} align="left">
                    {tagData !== null && tagData.length > 0 &&
                      <div className={classes.tagWrapper}>
                      {row.tag.length > 0 &&
                        row.tag.map(
                          (tag, index) =>
                            <Tooltip title={tagData[index]["tip"]} placement="top">
                              <Chip key={index} style={{ backgroundColor: '#ffed6f', color: 'black' }} size="small" label={tag}></Chip>
                            </Tooltip>
                      )
                      }
                      {/* <Autocomplete
                        multiple
                        id="tag-select-autocomplete"
                        options={tagData}
                        getOptionLabel={(option) => option.value || ""}
                        getOptionSelected={(option, value) => option.value.toString() === value.toString()}
                        value={selectedTag.tags !== undefined && selectedTag.tags.length > 0
                          ? row.fileName === selectedTag.fileName
                            ? tagData.filter(tag => selectedTag.tags.includes(tag.value.toString()) && row.fileName === selectedTag.fileName)
                            : row.tag.length > 0
                              ? tagData.filter(tag => row.tag.includes(tag.value.toString()))
                              : []
                          : row.tag.length > 0
                            ? tagData.filter(tag => row.tag.includes(tag.value.toString()))
                            : []
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            const selectedValues = newValue.map((tag) => tag.value.toString());
                            handleSelectTagChange(selectedValues, row);
                          } else {
                            handleSelectTagChange([]);
                          }
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={index}
                              size="small"
                              label={option.value}
                              {...getTagProps({ index })}
                              style={{ backgroundColor: '#ffed6f', color: 'black', }} // Set Chip color
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="選択する"
                            className={classes.tagSelect}
                          />
                        )}
                        renderOption={(option) => (
                          <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                            <Typography variant="body1">
                              {option.tip}
                            </Typography>
                          </div>
                        )}
                      /> */}
                      {/* <Button
                        variant="outlined"
                        size="small"
                        color="default"
                        className={classes.saveButton}
                        onClick={setSavedSelectedTagData}
                      >
                        {tagSavingLoader ? "保存中.." : "保存"}
                      </Button> */}
                      </div>
                    }
                    </TableCell>
                    <TableCell style={{width:"20%"}} align="center">{row.user}</TableCell>
                    <TableCell style={{width:"20%"}} align="center">{row.dateTime}</TableCell>
                    <TableCell>
                      <Link
                        to={getViewUrlView(row.fileName)}
                        target="_blank">
                        <IconButton>
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
                {tableData.length === 0 &&
                  <TableRow className={classes.tableRowNone}>
                    <TableCell colSpan={9} className={classes.noDataCell}>
                      データがありません
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {tableData.length > 0 &&
        <Grid item>
          <div className={classes.pagination}>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="secondary"
            />
          </div>
        </Grid>
          }
      </Grid>
      {hoveredImage && (
        <div
          className={classes.imagePopup}
          style={{
            top: calculatePopupTop(hoverPosition.y),
            left: calculatePopupLeft(hoverPosition.x),
          }}
        >
          <img src={hoveredImage} alt="Preview" width={300} />
        </div>
      )}
    </>
  );
};

PhotoTable.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dateRange: state.app.dateRange,
  periodType: state.app.periodType,
  homeImageData: state.home.homeImageData,
  totalAssetStats: state.home.totalAssetStats,
  filteredTotal: state.home.filteredTotal,
  selectedTag: state.app.selectedTag,
  tagSavingLoader: state.app.tagSavingLoader,
  filterType: state.app.filterType,
  reportData: state.report.reportData,
});

const mapDispatchToProps = (dispatch) => ({
  setPeriodType: (param) => dispatch(setPeriodType(param)),
  setCurrentPageNumber: (param) => dispatch(setCurrentPageNumber(param)),
  setSavedSelectedTagData: (param) => dispatch(setSavedSelectedTagData(param)),
  setSelectedTag: (param) => dispatch(setSelectedTag(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PhotoTable));
